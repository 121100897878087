<template>
  <v-flex v-if="property" xs12 class="property-container">
    <track-event label="property_form_toggle">
      <div
        class="toggle small btn btn-blank btn-flat btn-small"
        @click="toggleMode"
        id="property_form_toggle_mode"
        >{{ toggleText }}</div
      >
    </track-event>
    <transition-expand>
      <v-layout>
        <v-flex v-if="searchMode" xs8 vpx-1>
          <form
            autocomplete="off"
            onSubmit="return false"
            data-cy="inspection_address_search_form"
          >
            <gmap-autocomplete
              slot="activator"
              :disabled="disableGoogle"
              :bounds="bounds"
              placeholder="Address Search"
              data-cy="inspection_address_search"
              @change="updateSearchTerm"
              @place_changed="setPlace"
              id="property-address-search"
            >
            </gmap-autocomplete>
          </form>
          <div v-if="googleDead" class="google-dead">
            Unfortunately we're having trouble loading our address search.
            Please use the address form instead.
          </div>
        </v-flex>
        <v-flex v-if="searchMode" xs4 vpx-1 flex-end class="not-found">
          <span class="small">
            <span class="not-found-label">Can't find your address?</span>
            <track-event label="cant_find_address">
              <strong
                @click="toggleMode"
                id="property-form-enable-manual-mode"
                class="fg-blue pointer not-found-link"
                >Click here.</strong
              >
            </track-event>
          </span>
        </v-flex>
      </v-layout>
    </transition-expand>
    <transition-expand>
      <v-layout v-if="!searchMode" wrap class="property-form">
        <v-flex xs10 vpx-1>
          <v-text-field
            v-model="property.attributes.address"
            label="Address"
            id="address-address"
            @keyup="debounceZillowLookup"
          />
        </v-flex>
        <v-flex xs2 vpx-1>
          <v-text-field
            v-model="property.attributes.unit"
            label="Unit"
            id="address-unity"
            @keyup="debounceUnitUpdate"
          />
        </v-flex>
        <v-flex xs6 vpx-1>
          <v-text-field
            v-model="property.attributes.city"
            label="City"
            id="address-city"
            @keyup="debouncePropertyUpdate"
          />
        </v-flex>
        <v-flex xs3 vpx-1>
          <v-text-field
            v-model="property.attributes.state"
            label="State"
            id="address-state"
            @keyup="debouncePropertyUpdate"
          />
        </v-flex>
        <v-flex xs3 vpx-1>
          <v-text-field
            v-model="property.attributes.zip"
            label="Zip"
            id="address-zip"
            @keyup="debounceZillowLookup"
          />
        </v-flex>
        <template v-if="hasCountyDataEnabled">
          <v-flex xs6 vpx-1>
            <v-text-field
              v-model="property.attributes.county"
              :label="countyLabel"
              id="address-county"
              @keyup="debouncePropertyUpdate"
            />
          </v-flex>
          <v-flex xs6 vpx-1 />
        </template>
        <template v-if="canUseSquareFeet">
          <v-flex xs4 vpx-1>
            <v-text-field
              v-if="displayMetric"
              type="number"
              v-model="property.attributes.square_feet"
              label="Square Meters (m²)"
              @keyup="debounceSquareFeetPropertyUpdate"
              id="address-square-feet"
            />
            <v-text-field
              v-else
              type="number"
              class="property-square-feet"
              v-model="property.attributes.square_feet"
              label="Square Feet"
              @keyup="debounceSquareFeetPropertyUpdate"
              lang="nl"
              id="address-square-feet"
            />
          </v-flex>
        </template>
        <template v-if="canUseYearBuilt">
          <v-flex xs4 vpx-1>
            <v-text-field
              type="number"
              class="property-year-built"
              v-model="property.attributes.year_built"
              label="Year Built"
              @keyup="debounceYearBuiltPropertyUpdate"
              id="address-year-built"
            />
          </v-flex>
        </template>
        <template v-if="canUseFoundation">
          <v-flex xs4 vpx-1 class="property-foundation">
            <v-select
              :items="foundations"
              label="Foundation"
              v-model="property.attributes.foundation"
              @input="debouncePropertyUpdate"
              id="property-foundation-dropdown"
            />
          </v-flex>
        </template>
      </v-layout>
    </transition-expand>
  </v-flex>
</template>

<script>
import { gmapApi as Google } from 'vue2-google-maps'
import { debounce } from 'lodash'
import { mapState } from 'vuex'

import axios from '../../AxiosService'
import dig from '../../Dig'
import Property from '../../models/Property'
import TrackEvent from '../TrackEvent'
import TransitionExpand from './TransitionExpand'

export default {
  components: {
    TrackEvent,
    TransitionExpand,
  },
  computed: {
    ...mapState('inspection', ['company']),
    canUseFoundation() {
      return this.company.attributes.company_type.can_use_foundation
    },
    canUseSquareFeet() {
      return this.company.attributes.company_type.can_use_square_footage
    },
    canUseYearBuilt() {
      return this.company.attributes.company_type.can_use_year_built
    },
    countyLabel() {
      return dig(this.company, 'attributes.settings.county_label') || 'County'
    },
    displayMetric() {
      return dig(this.company, 'attributes.settings.display_metric')
    },
    googleDead() {
      return (
        this.disableGoogle === true &&
        this.googleAttempts >= this.googleMaxAttempts
      )
    },
    hasCountyDataEnabled() {
      return dig(this.company, 'attributes.settings.county_data')
    },
    manuallyChangedZillowData() {
      return this.manuallyChangedYearBuilt || this.manuallyChangedSquareFeet
    },
    searchMode() {
      return this.mode === 'search'
    },
    toggleText() {
      return this.searchMode ? 'Address Form' : 'Address Search'
    },
  },
  data() {
    let info = {
      bounds: null,
      disableGoogle: true,
      externalLock: false,
      foundations: [
        { text: '', value: null },
        { text: 'Basement', value: 'basement' },
        { text: 'Slab', value: 'slab' },
        { text: 'Crawlspace', value: 'crawlspace' },
      ],
      googleAttempts: 0,
      googleMaxAttempts: 5,
      googleSearch: '',
      manuallyChangedSquareFeet: false,
      manuallyChangedYearBuilt: false,
      mode: 'search',
      property: JSON.parse(JSON.stringify(Property)),
    }

    return info
  },
  methods: {
    emitProperty() {
      this.$store.dispatch('notifier/dismissCategory', 'place')

      if (!this.property.attributes.country) {
        this.property.attributes.country = 'US'
      }

      this.$emit('propertyReady', this.property)
    },
    geocodeAddress() {
      return axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${this.property.attributes.address},${this.property.attributes.zip}&key=${process.env.GOOGLE_GEOCODING_API_KEY}`
        )
        .then((response) => {
          let results = dig(response, 'data.results', [])
          if (results.length > 0) this.setPlace(results[0])
        })
        .catch((e) => {
          console.log(e)
        })
    },
    propertyUpdated() {
      this.$emit('propertyUpdated', this.property)
    },
    setBounds() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const geolocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }

          const Circle = Google().maps.Circle
          const circle = new Circle({
            center: geolocation,
            radius: position.coords.accuracy,
          })

          this.bounds = circle.getBounds()
        })
      }
    },
    setPlace(place) {
      if (place.address_components.length > 0) {
        let components = {}

        for (let i = 0; i < place.address_components.length; i++) {
          let addressType = place.address_components[i].types[0]
          if (addressType === 'locality') {
            components[addressType] = place.address_components[i].long_name
          } else {
            components[addressType] = place.address_components[i].short_name
          }
        }
        if (this.searchMode) {
          let addr = [components.street_number, components.route].filter(
            (c) => c
          )

          if (addr.length === 2) {
            this.property.attributes.address = addr.join(' ')
          } else {
            this.property.attributes.address = ''
            this.$store.dispatch('notifier/show', {
              type: 'Warning',
              msg: "We had some issues fetching this location.<br/>Fill in the location form to make sure we're looking at the right place.",
            })
          }

          this.property.attributes.city = components.locality
          this.property.attributes.state =
            components.administrative_area_level_1
          const county = (components.administrative_area_level_2 || '')
            .trim()
            .replace(/ county/i, '')
          this.property.attributes.county = county
          this.property.attributes.zip = components.postal_code
          this.property.attributes.country = components.country
        }

        this.property.attributes.google_places_id = place.place_id
        if (typeof place.geometry.location.lat === 'function') {
          this.property.attributes.lat = place.geometry.location.lat()
          this.property.attributes.lng = place.geometry.location.lng()
        } else {
          this.property.attributes.lat = place.geometry.location.lat
          this.property.attributes.lng = place.geometry.location.lng
        }

        this.zillowLookup()
      }
    },
    toForm() {
      this.mode = 'form'
    },
    toggleMode() {
      if (this.searchMode) {
        if (!this.property.attributes.address && this.googleSearch.length > 0) {
          this.property.attributes.address = this.googleSearch
        }
        this.mode = 'form'
        this.geocodeAddress()
      } else {
        this.property.attributes.address = null
        this.property.attributes.unit = null
        this.mode = 'search'
      }
    },
    resetZillow(partial = false) {
      let removeAtts = [
        'external_image_url',
        'lat',
        'lng',
        'bathrooms',
        'bedrooms',
        'lot_size',
        'parking',
        'square_feet',
        'year_built',
        'zillow_id',
        'google_places_id',
      ]
      if (partial) {
        removeAtts = removeAtts.filter(
          (att) => !['external_image_url', 'lat', 'lng'].includes(att)
        )
      }
      let unzillowProperty = JSON.parse(JSON.stringify(this.property))
      removeAtts.forEach((att) => {
        unzillowProperty.attributes[att] = null
      })
      this.manuallyChangedSquareFeet = false
      this.manuallyChangedYearBuilt = false
      this.property = unzillowProperty
    },
    setStreetViewImg() {
      let location = [
        this.property.attributes.address.replace('.', ''),
        this.property.attributes.zip,
      ].join(',')
      let streetViewUrl = `https://maps.googleapis.com/maps/api/streetview?size=1200x900&location=${location}&key=${process.env.GOOGLE_STREET_VIEW_API_KEY}`

      this.property.attributes.external_image_url = streetViewUrl
    },
    updateSearchTerm(e) {
      this.googleSearch = e.target.value
    },
    waitForGoogle() {
      if (window.google) {
        this.disableGoogle = false
        this.setBounds()
      } else {
        this.googleAttempts += 1
        if (this.googleAttempts >= this.googleMaxAttempts) {
          this.toggleMode()
        } else {
          setTimeout(() => {
            this.waitForGoogle()
          }, 250)
        }
      }
    },
    zillowLookup() {
      let country = dig(this.property, 'attributes.country', 'US')
      if (country !== 'US') {
        this.setStreetViewImg()
        this.toForm()
        this.emitProperty()
        this.propertyUpdated()
        return
      }

      if (!this.externalLock) {
        let combinedAddress = [
          dig(this.property, 'attributes.address', ''),
          dig(this.property, 'attributes.unit', ''),
        ].join(' ')

        let address = encodeURIComponent(
          dig(this.property, 'attributes.address', '')
        )
        let city = encodeURIComponent(dig(this.property, 'attributes.city', ''))
        let state = encodeURIComponent(
          dig(this.property, 'attributes.state', '')
        )
        let zip = encodeURIComponent(dig(this.property, 'attributes.zip', ''))
        let query =
          'address=' +
          combinedAddress +
          '&city=' +
          city +
          '&state=' +
          state +
          '&postal_code=' +
          zip

        if (this.company) {
          query = query + `&company_id=${this.company.id}`
        }

        const propertyAttributes = this.property.attributes
        if (address.length > 0 && zip.length > 0) {
          axios
            .get(`/home_data/search?${query}`)
            .then((response) => {
              let data = response.data

              if (data) {
                this.property.attributes.zillow_id = data.zpid

                if (dig(data, 'details.images.image.url', '').length > 0) {
                  this.property.attributes.external_image_url =
                    data.details.images.image.url[0]
                } else {
                  this.setStreetViewImg()
                }

                if (data.address) {
                  if (!this.property.attributes.lat)
                    this.property.attributes.lat = data.address.latitude
                  if (!this.property.attributes.lng)
                    this.property.attributes.lng = data.address.longitude
                  if (!this.property.attributes.city)
                    this.property.attributes.city = data.address.city
                  if (!this.property.attributes.state)
                    this.property.attributes.state = data.address.state
                }

                this.property.attributes.bedrooms = data.bedrooms
                this.property.attributes.bathrooms = data.bathrooms

                if (!this.property.attributes.year_built)
                  this.property.attributes.year_built = data.yearBuilt

                if (data.finishedSqFt) {
                  // clean up zillow data
                  const sqft = data.finishedSqFt.replace('sq ft', '')

                  // if we're using metric, convert square feet to square meters
                  if (!this.property.attributes.square_feet) {
                    if (this.displayMetric) {
                      this.property.attributes.square_feet =
                        this.convertSqftToSqm(sqft)
                    } else {
                      this.property.attributes.square_feet = sqft
                    }
                  }
                }

                this.property.attributes.lot_size = dig(data, 'lotSizeSqFt')
                this.property.attributes.parking = dig(
                  data,
                  'coveredParkingSpaces'
                )
                this.property.attributes.zillowData = data
              }
              this.emitProperty()
              this.toForm()
              this.propertyUpdated()
            })
            .catch((error) => {
              console.log(error)
              this.property.attributes = propertyAttributes
              this.setStreetViewImg()
              this.toForm()
              this.emitProperty()
              this.propertyUpdated()
            })
        } else {
          this.resetZillow()
        }
      } else {
        this.propertyUpdated()
      }
    },
    convertSqftToSqm(value) {
      const squareFeet = parseFloat(value)

      if (squareFeet > 0) {
        const squareMeters = parseFloat(value) / 10.764
        return Math.round(squareMeters)
      }

      return null
    },
  },
  mounted() {
    this.waitForGoogle()

    let vm = this

    this.debouncePropertyUpdate = debounce((e) => {
      vm.propertyUpdated()
    }, 300)
    this.debounceSquareFeetPropertyUpdate = (e) => {
      vm.property.attributes.square_feet = vm.property.attributes.square_feet
        .replace('.', '')
        .replace(',', '')
      vm.manuallyChangedSquareFeet = true
      this.debouncePropertyUpdate(e)
    }
    this.debounceYearBuiltPropertyUpdate = (e) => {
      vm.manuallyChangedYearBuilt = true
      this.debouncePropertyUpdate(e)
    }
    this.debounceUnitUpdate = (e) => {
      if (vm.manuallyChangedZillowData) return vm.debouncePropertyUpdate(e)

      vm.debounceZillowLookup(e)
    }
    this.debounceZillowLookup = debounce((e) => {
      if (!vm.externalLock) {
        if (
          dig(vm, 'property.attributes.address', '').length > 0 &&
          dig(vm, 'property.attributes.zip', '').length > 3
        ) {
          vm.property.attributes.google_places_id = null
          vm.resetZillow()
          vm.geocodeAddress().then(() => {
            vm.zillowLookup()
          })
        }
      } else {
        vm.debouncePropertyUpdate()
      }
    }, 1000)
  },
  props: ['zillowReset'],
  watch: {
    zillowReset(newV, oldV) {
      if (newV === true) {
        this.resetZillow()
        this.externalLock = true
        this.toForm()
      }
    },
  },
}
</script>

<style scoped>
.property-container {
  position: relative;
}
.property-container .toggle {
  position: absolute;
  right: 0;
  top: -2.75em;
  color: #5c9ccf;
  cursor: pointer;
  user-select: none;
}

.google-dead {
  font-style: italic;
  margin-top: 1em;
  color: #f9890e;
}

.flex-end {
  display: flex;
  align-items: flex-end;
}

@media (max-width: 800px) {
  .not-found {
    text-align: center;
  }

  .not-found .not-found-label {
    display: block;
  }
}
</style>
