<template>
  <v-layout wrap id="scheduler-contact-info">
    <v-layout wrap fill-width section>
      <v-flex xs12>
        <v-flex xs12 vpx-1>
          <h3>
            <i class="fa fa-home" />
            {{ clientLabel.label }}
          </h3>
        </v-flex>
        <v-flex xs12>
          <client-search
            class="primary-client-search"
            initialMode="form"
            @change="setClient"
            id="client-search"
          />
        </v-flex>
        <v-flex xs12 v-if="!hasClient2">
          <div @click="hasClient2 = true" class="fg-blue small pointer">
            <i>Have a second {{ clientLabel.label }}? Click here.</i>
          </div>
        </v-flex>
      </v-flex>
    </v-layout>

    <v-layout v-if="hasClient2" wrap fill-width section>
      <v-flex xs12>
        <v-flex xs12 vpx-1>
          <h3>
            <i class="fa fa-home" />
            {{ clientLabel.label }} #2
          </h3>
        </v-flex>
        <v-flex xs12>
          <client-search initialMode="form" @change="setClient2" id="secondary-client-search" />
        </v-flex>
      </v-flex>
    </v-layout>

    <template v-if="canUseAgents">
      <v-layout wrap fill-width section>
        <v-flex xs12>
          <template v-if="buyingAgent">
            <v-flex xs12 vpx-1>
              <h3>
                <i class="fa fa-user" />
                Agent
              </h3>
            </v-flex>
            <v-flex xs12>
              <agent-search
                @change="setAgent"
                :inline="true"
                :hideCCEmail="true"
                :captureAddress="
                  company.attributes.settings.capture_buyer_agent_address
                "
                id="buying-agent-search"
              />
            </v-flex>
          </template>
          <v-flex xs12 v-if="!hasListingAgent">
            <br />
            <div @click="hasListingAgent = true" class="small fg-blue pointer">
              <i>Click here to add a listing agent.</i>
            </div>
          </v-flex>
        </v-flex>
      </v-layout>

      <v-layout v-if="hasListingAgent" wrap fill-width section>
        <v-flex xs12>
          <v-flex xs12 vpx-1>
            <h3>
              <i class="fa fa-user" />
              Listing Agent
            </h3>
          </v-flex>
          <v-flex xs12>
            <agent-search
              @change="setListingAgent"
              :inline="true"
              :hideCCEmail="true"
              :captureAddress="
                company.attributes.settings.capture_selling_agent_address
              "
              id="listing-agent-search"
            />
          </v-flex>
        </v-flex>
      </v-layout>
    </template>
  </v-layout>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { mapState } from 'vuex'
import AgentSearch from '../AgentSearch'
import TrackEvent from '../../TrackEvent'
import ClientSearch from '../ClientSearch'

export default {
  components: {
    AgentSearch,
    TrackEvent,
    ClientSearch,
  },
  computed: {
    ...mapState('inspection', ['company', 'userProfile']),
    buyingAgent() {
      if (
        this.userProfile.id &&
        this.userProfile.id === this.$route.query.buying_agent_id
      ) {
        return false
      } else {
        return true
      }
    },
    canUseAgents() {
      return this.company.attributes.company_type.can_use_agents
    },
  },
  data() {
    return {
      clientLabel: window.clientLabel,
      hasClient2: false,
      hasListingAgent: false,
    }
  },
  methods: {
    setAgent(agent) {
      this.inspection.agent = agent
    },
    setClient(client) {
      this.inspection.buyer = client
    },
    setClient2(client) {
      this.inspection.buyer_2 = client
    },
    setListingAgent(agent) {
      this.inspection.selling_agent = agent
    },
  },
  props: ['inspection'],
}
</script>

<style lang="scss">
#scheduler-contact-info {
  .client-search-container .toggle {
    display: none;
  }
  .file-picker-container {
    display: none;
  }
  .tagger-container {
    display: none;
  }
  .notes-field {
    display: none;
  }

  .primary-client-search .required label:before {
    content: '* ';
    color: #d53636;
    font-weight: 300;
  }

  .search-results {
    position: relative;
  }
}
</style>
