var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layout',{attrs:{"wrap":"","service-selector":"","service-selection-component":""}},[_c('v-flex',{class:_vm.displayClasses,attrs:{"xs12":"","vpx-1":""}},[_c('v-select',{attrs:{"items":_vm.services,"menu-props":_vm.menuProps,"item-text":"attributes.name","item-value":"attributes.id","data-cy":"inspection_service_select","placeholder":"Select Services","attach":true,"multiple":"","id":"service-selection-dropdown"},on:{"input":_vm.toggleSelection},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-flex',{staticClass:"service-selection",attrs:{"xs12":"","id":`service-item__${data.item.index}`}},[_c('v-flex',{attrs:{"xs2":"","md1":""}},[_c('v-checkbox',{attrs:{"id":`service-checkbox__${data.item.attributes.name.replace(
                /[^a-zA-Z0-9]/g,
                ''
              )}`},model:{value:(data.item.selected),callback:function ($$v) {_vm.$set(data.item, "selected", $$v)},expression:"data.item.selected"}})],1),_vm._v(" "),_c('v-layout',{staticClass:"name name-and-description",attrs:{"wrap":""}},[_c('div',[_vm._v(_vm._s(data.item.attributes.name))]),_vm._v(" "),_c('div',{staticClass:"light small"},[_vm._v(_vm._s(data.item.attributes.description))])])],1)]}}]),model:{value:(_vm.serviceIds),callback:function ($$v) {_vm.serviceIds=$$v},expression:"serviceIds"}})],1),_vm._v(" "),_vm._l((_vm.serviceIds),function(serviceId){return [(_vm.hasAddOns[serviceId])?_c('v-flex',{key:serviceId,class:_vm.displayClasses,attrs:{"xs12":"","vpx-1":""}},[_c('v-select',{attrs:{"items":_vm.addOnsForService[serviceId],"menu-props":_vm.menuProps,"item-text":"attributes.name","item-value":"attributes.id","placeholder":"Select Add Ons","label":_vm.addOnLabel(serviceId),"attach":true,"multiple":""},on:{"input":_vm.toggleAddOnSelection},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-flex',{staticClass:"service-selection",attrs:{"xs12":""}},[_c('v-flex',{attrs:{"xs1":""}},[_c('v-checkbox',{attrs:{"id":`service-addon-checkbox__${data.item.attributes.name.replace(
                  /[^a-zA-Z0-9]/g,
                  ''
                )}`},model:{value:(data.item.selected),callback:function ($$v) {_vm.$set(data.item, "selected", $$v)},expression:"data.item.selected"}})],1),_vm._v(" "),_c('v-layout',{staticClass:"name name-and-description",attrs:{"wrap":""}},[_c('div',[_vm._v(_vm._s(data.item.attributes.name))]),_vm._v(" "),_c('div',{staticClass:"light small"},[_vm._v(_vm._s(data.item.attributes.description))])])],1)]}}],null,true),model:{value:(_vm.addOnIds),callback:function ($$v) {_vm.addOnIds=$$v},expression:"addOnIds"}})],1):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }