import * as actions from './actions'
import * as mutations from './mutations'

export default {
  namespaced: true,
  state: {
    addOns: [],
    charges: [],
    company: {
      id: undefined,
      attributes: {
        settings: {},
      },
    },
    customFields: [],
    inspectors: [],
    inspection: {},
    services: [],
    userProfile: {
      id: undefined,
      attributes: {},
    },
  },
  actions,
  mutations,
}
