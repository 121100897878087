export const notify = ({ commit, dispatch, state }, notification) => {
  if (
    !state.notifications.find(
      (n) => n.msg === notification.msg && n.type === notification.type
    )
  ) {
    commit('addNotification', notification)
  }
}

export const dismiss = ({ commit, dispatch, state }, notificationId) => {
  commit('removeNotification', notificationId)
}

export const dismissAll = ({ commit, dispatch, state }) => {
  commit('removeAllNotifications')
}

export const dismissCategory = ({ commit, dispatch, state }, category) => {
  commit('removeNotificationCategory', category)
}

export const deleteCategory = ({ commit, dispatch, state }, category) => {
  commit('deleteNotificationCategory', category)
}

export const show = ({ commit, dispatch, state }, { type, msg }) => {
  commit(`notifier/setShow${type}`, msg, { root: true })
}
