import Vue from 'vue'
import Vuex from 'vuex'

import inspection from './store'
import notifier from '../notifier/store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    inspection,
    notifier,
  },
  state: {},
})
