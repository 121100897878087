/* eslint-disable */

const isObject = function (a) {
  return (
    'object' == typeof a &&
    '[object Object]' === Object.prototype.toString.call(a)
  )
}

const dig = function () {
  var a = arguments.length
  if (a < 2) throw 'dig needs at least 2 arguments'
  var b = arguments[0],
    c = arguments[1],
    d = null,
    e = null
  if (4 == a || 5 == a) {
    if (arguments[2] !== !0)
      throw 'The third argument is expected to be true for the set signature of dig'
    d = arguments[3]
    var f = !1
    5 == a && (f = Boolean(arguments[4]))
    var g = c.slice(1).split('.'),
      h = b
    if ('undefined' == typeof h) return h
    for (; g.length; ) {
      var i = g.shift()
      if (0 == g.length) {
        if (f && this.isObject(h[i])) {
          h[i] = Object.assign({}, h[i], d)
          break
        }
        h[i] = d
      } else 'undefined' == typeof h[i] && (h[i] = {})
      h = h[i]
    }
    return b
  }
  if ((3 == a && (e = arguments[2]), c.length < 1))
    throw 'Invalid path ' + c + ' supplied'
  if (c[0] !== '.') {
    c = '.' + c
  }
  var j = c.slice(1).split('.'),
    h = b

  if ('undefined' == typeof h || null === h) return h
  try {
    for (var k; j.length; ) {
      k = j.shift()
      {
        if ('undefined' == typeof h[k] || h[k] == null) return e
        h = h[k]
      }
    }
  } catch (a) {
    return e
  }
  return h
}

export default dig
