<template>
  <div class="client-search-container" :id="id">
    <div
      class="toggle small btn btn-blank btn-flat btn-small"
      @click="toggleMode"
      :id="`toggle-client-search__${clientIndex}`"
      >{{ toggleText }}</div
    >
    <transition-expand>
      <v-layout v-if="searchMode" wrap>
        <v-flex md12 vpx-1>
          <div class="client-search">
            <autocomplete-search
              :id="`${id}-input`"
              :url="searchUrl"
              :queryParams="queryParams"
              :placeholder="'Search for a ' + clientLabel.capitalize"
              selectionText="name"
              @click="resultClicked"
            >
              <template slot="result" slot-scope="result">
                <div class="buyer-result">
                  <img class="circle" :src="result.result.image" />
                  <div class="name-combo">
                    <div class="name">{{ result.result.name }}</div>
                    <div class="small light">
                      {{ result.result.email }}
                    </div>
                  </div>
                </div>
              </template>
              <template slot="appendResults">
                <li @click="toggleMode" class="light"
                  >Not here? Click to add a new {{ clientLabel.capitalize }}</li
                >
              </template>
              <template slot="noResults">
                <div class="light">
                  No results found. Click to add a new
                  {{ clientLabel.capitalize }}
                </div>
              </template>
            </autocomplete-search>
          </div>
        </v-flex>
      </v-layout>
    </transition-expand>
    <transition-expand>
      <div v-if="!searchMode" class="client-search buyer-form">
        <v-layout wrap>
          <v-flex md12 vpx-1>
            <v-checkbox
              class=""
              v-model="buyer.attributes.show_as_company"
              label="Client is a Company/Organization"
            />
          </v-flex>
          <v-flex v-if="buyer.attributes.show_as_company" md12 vpx-1>
            <v-text-field
              class="required"
              v-model="buyer.attributes.first_name"
              label="Company/Organization"
            />
          </v-flex>
          <v-flex v-if="!buyer.attributes.show_as_company" md6 vpx-1>
            <v-text-field
              class="required"
              v-model="buyer.attributes.first_name"
              label="First Name"
              :id="`client-first-name__${clientIndex}`"
            />
          </v-flex>
          <v-flex v-if="!buyer.attributes.show_as_company" md6 vpx-1>
            <v-text-field
              class="required"
              v-model="buyer.attributes.last_name"
              label="Last Name"
              :id="`client-last-name__${clientIndex}`"
            />
          </v-flex>
          <v-flex md6 vpx-1>
            <v-text-field
              class="buyer-email required"
              v-model="buyer.attributes.email"
              label="Email"
              :rules="[rules.validEmail]"
              :id="`client-email__${clientIndex}`"
            />
          </v-flex>
          <v-flex md6 vpx-1 v-if="!renderedInOnlineScheduler">
            <v-text-field
              v-model="buyer.attributes.cc_email"
              label="CC Email"
              hint="For this Inspection"
              :rules="[rules.validCCEmail]"
              :disabled="!ccEnabled"
              :id="`client-cc-email__${clientIndex}`"
            />
          </v-flex>
          <v-flex md6 vpx-1>
            <v-text-field
              class="required"
              v-model="buyer.attributes.phone"
              label="Phone"
              :id="`client-phone__${clientIndex}`"
              @input="formatPhoneNumber"
            />
          </v-flex>
          <v-flex xs12 vpx-1 v-if="captureAddress">
            <v-text-field
              v-model="buyer.attributes.address"
              label="Address"
              :id="`client-address__${clientIndex}`"
            />
          </v-flex>
          <v-layout xs12 v-if="captureAddress">
            <v-flex xs7 vpx-1>
              <v-text-field
                v-model="buyer.attributes.city"
                label="City"
                :id="`client-city__${clientIndex}`"
              />
            </v-flex>
            <v-flex xs2 vpx-1>
              <v-text-field
                v-model="buyer.attributes.state"
                label="State"
                :id="`client-state__${clientIndex}`"
              />
            </v-flex>
            <v-flex xs3 vpx-1>
              <v-text-field
                v-model="buyer.attributes.zip"
                label="Zip"
                :id="`client-zip__${clientIndex}`"
              />
            </v-flex>
          </v-layout>
          <v-flex v-if="autotracks" xs12 sm6 vpx-1 class="tagger-container">
            <tagger
              v-model="buyer.attributes.tag_string"
              ref="tagger"
              tagType="contact"
              :companyId="company.id"
            />
          </v-flex>
          <v-flex xs12 sm6 vpx-1 notes-field>
            <v-textarea
              label="Notes"
              v-model="buyer.attributes.notes"
              :id="`client-notes__${clientIndex}`"
            >
            </v-textarea>
          </v-flex>
          <v-flex
            xs12
            sm6
            vpx-1
            notes-field
            v-if="userProfile.attributes.is_company_admin"
          >
            <v-textarea
              label="Private Notes"
              v-model="buyer.attributes.private_notes"
              :id="`client-private-notes__${clientIndex}`"
            >
            </v-textarea>
          </v-flex>
        </v-layout>
      </div>
    </transition-expand>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AutocompleteSearch from '../shared/AutocompleteSearch'
import Tagger from './Tagger'
import TransitionExpand from './TransitionExpand'

import { formatPhone } from '@spectora/utils'

export default {
  components: {
    AutocompleteSearch,
    Tagger,
    TransitionExpand,
  },
  computed: {
    ...mapState('inspection', ['company', 'userProfile']),
    autotracks() {
      return (
        this.company.attributes.settings.trial_auto_tracks ||
        this.company.attributes.settings.enable_auto_tracks
      )
    },
    captureAddress() {
      return this.company.attributes.settings.capture_buyer_address
    },
    renderedInOnlineScheduler() {
      return ['schedule', 'scheduleStep'].includes(this.$route.name)
    },
    searchMode() {
      return this.mode === 'search'
    },
    searchUrl() {
      return `/contacts_search`
    },
    toggleText() {
      return this.searchMode
        ? `${this.clientLabel.capitalize} Form`
        : `${this.clientLabel.capitalize} Search`
    },
    queryParams() {
      return {
        company_id: this.company.id,
      }
    },
  },
  data() {
    return {
      buyer: {
        attributes: {},
      },
      clientLabel: window.clientLabel || {},
      mode: 'search',
      rules: {
        validEmail: (value) =>
          !!(value || '').match(/^[^@\s]+@[^@\s]+$/) || 'This email is invalid',
        validCCEmail: (value) => this.validCC(value) || 'This email is invalid',
      },
    }
  },
  methods: {
    emitClient() {
      if (this.buyer.attributes.show_as_company)
        this.buyer.attributes.last_name = ''

      Object.keys(this.buyer.attributes).forEach((k) => {
        if (k === 'show_as_company') return

        if (this.buyer.attributes[k])
          this.buyer.attributes[k] = this.buyer.attributes[k].trim()
      })

      this.$emit('change', this.buyer)
    },
    fillInForm(result) {
      this.buyer.attributes = {}

      this.buyer.attributes.first_name = result.first_name
      this.buyer.attributes.last_name = result.last_name
      this.buyer.attributes.email = result.email
      this.buyer.attributes.cc_email = result.cc_email
      this.buyer.attributes.phone = result.phone
      this.buyer.attributes.address = result.address
      this.buyer.attributes.city = result.city
      this.buyer.attributes.state = result.state
      this.buyer.attributes.zip = result.zip
      this.buyer.attributes.tag_string = (result.tag_list || []).join(',')
      this.buyer.attributes.notes = result.notes
      this.buyer.attributes.private_notes = result.private_notes
      this.buyer.attributes.show_as_company = result.show_as_company

      this.buyer = JSON.parse(JSON.stringify(this.buyer))

      setTimeout(() => {
        // Allows the interface to render since the ref may not exist otherwise
        if (this.$refs.tagger) {
          this.$refs.tagger.parseValue()
        }
      }, 500)
    },
    resultClicked(result) {
      this.fillInForm(result || {})
      this.toggleMode()
    },
    toggleMode() {
      if (this.searchMode) {
        this.mode = 'form'
        this.$emit('clientSelected', null)
      } else {
        this.mode = 'search'
      }
    },
    validCC(value) {
      if (value) {
        return !value
          .replace(' ', '')
          .split(',')
          .find((e) => {
            return !e.match(/^[^@\s]+@[^@\s]+$/)
          })
      } else {
        return true
      }
    },
    formatPhoneNumber(value) {
      this.buyer.attributes.phone = formatPhone({
        value,
        locale: this.company.attributes.country || 'US',
      })
    },
  },
  mounted() {
    if (this.initialMode) this.mode = this.initialMode
    if (this.attributes) {
      this.fillInForm(JSON.parse(JSON.stringify(this.attributes)))
    }
  },
  props: {
    initialMode: {},
    attributes: {},
    ccEnabled: {},
    clientIndex: {
      default: 1,
    },
    id: {},
  },
  watch: {
    buyer: {
      handler: function (newVal) {
        this.emitClient()
      },
      deep: true,
    },
  },
}
</script>

<style scoped>
.client-search-container {
  position: relative;
}
.client-search-container .toggle {
  position: absolute;
  right: 0;
  top: -2.75em;
  color: #5c9ccf;
  cursor: pointer;
  user-select: none;
}

.search-results .no-results {
  color: #555;
}
.search-results .buyer-result {
  display: flex;
  align-items: center;
}
.search-results .buyer-result img {
  width: 2em;
  height: 2em;
  margin-right: 1em;
  display: inline-block;
}
.search-results .buyer-result .name-combo {
  display: inline-block;
}
</style>

<style>
.client-search-container .notes-field label {
  margin: 6px;
}
</style>
