import axios from '../../AxiosService'
import dig from '../../Dig'
import errorReporter from '@/plugins/errorReporterPlugin'

const genericError = 'An error has occurred. Your changes likely did not save.'
const loadingError = "We're having trouble loading data. Please try again."

export const calculateCharges = ({ commit, dispatch, state }, inspection) => {
  return new Promise((resolve, reject) => {
    let property = inspection.property
    let propertyQS = [
      ['sqft', property.attributes.square_feet],
      ['year', property.attributes.year_built],
      [
        'address',
        [
          property.attributes.address,
          property.attributes.city,
          property.attributes.state,
        ]
          .map((a) => a)
          .join(', '),
      ],
      ['zip', property.attributes.zip],
      ['county', property.attributes.county],
      ['foundation', property.attributes.foundation || ''],
    ]
      .filter((pair) => pair[1])
      .map((pair) => pair.map(encodeURIComponent).join('='))
      .join('&')

    let customFieldQS = ''
    let counter = 0
    let fields = inspection.custom_fields_attributes || []

    let inspector = inspection.inspector
    let inspectorId = ''
    if (inspector) {
      inspectorId = inspector.id
    }

    fields.forEach((cf) => {
      if (
        ['text', 'dropdown', 'number', 'checkbox'].includes(
          cf.attributes.response_format
        )
      ) {
        if (cf) {
          customFieldQS += `&custom_fields[${counter}][custom_field_template_id]=${cf.attributes.custom_field_template_id}`
          customFieldQS += `&custom_fields[${counter}][value]=${escape(
            cf.attributes.value
          )}`
          counter += 1
        }
      }
    })

    let serviceRequests = (inspection.services || []).map((s) => {
      return axios.get(
        `/api/v1/public/services/${
          s.id
        }/calculate_charge?inspector_id=${inspectorId}&company_id=${
          state.company.id
        }&${propertyQS}&discount=${
          inspection.attributes.discount_code || ''
        }${customFieldQS}`
      )
    })
    let addonRequests = (inspection.add_ons || []).map((s) => {
      return axios.get(
        `/api/v1/public/service_add_ons/${s.id}/calculate_charge?company_id=${
          state.company.id
        }&discount=${
          inspection.attributes.discount_code || ''
        }&inspector_id=${inspectorId}&${propertyQS}${customFieldQS}`
      )
    })

    Promise.all(serviceRequests.concat(addonRequests)).then((response) => {
      commit('setValue', {
        key: 'charges',
        value: response.map((r) => {
          return r.data
        }),
      })
      resolve(response)
    })
  })
}

export const createInspection = ({ commit, dispatch, state }, params) => {
  return new Promise((resolve, reject) => {
    axios
      .post('/inspections.json', params)
      .then((response) => {
        commit('setValue', { key: 'inspection', value: response.data })
        resolve(response)
      })
      .catch((error) => {
        commit(
          'notifier/showError',
          buildError(dig(error, 'response.data.error')),
          { root: true }
        )
        errorReporter.error('Error creating inspection', error)
        reject(error)
      })
  })
}

export const fetchAgreements = ({ commit, dispatch, state }) => {
  return null // used in service selection but we dont need
}

export const fetchCompany = ({ commit, dispatch, state }, params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/v1/public/companies/${params.id}?view=${params.view || ''}`)
      .then(function (response) {
        commit('setValue', { key: 'company', value: response.data.data })
        resolve(response.data.data)
      })
      .catch(function (error) {
        commit('notifier/showError', loadingError, { root: true })
        errorReporter.error('Error fetching company', error)
        reject(error)
      })
  })
}

export const fetchInspectors = ({ commit, dispatch, state }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/v1/public/inspectors?company_id=${state.company.id}`)
      .then((response) => {
        commit('setValue', { key: 'inspectors', value: response.data.data })
        resolve(response.data.data)
      })
      .catch((error) => {
        commit('notifier/showError', loadingError, { root: true })
        reject(error)
        errorReporter.error('Error fetching inspectors', error)
      })
  })
}

export const fetchCustomFields = ({ commit, dispatch, state }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/api/v1/public/custom_field_templates?company_id=${state.company.id}&hidden=false`
      )
      .then((response) => {
        commit('setValue', { key: 'customFields', value: response.data.data })
        resolve(response.data.data)
      })
      .catch((error) => {
        commit('notifier/showError', loadingError, { root: true })
        errorReporter.error('Error fetching custom field templates', error)
        reject(error)
      })
  })
}

export const fetchServices = ({ commit, dispatch, state }, options = {}) => {
  const companyId = state.company.id
  const url = `/api/v1/public/services?company_id=${companyId}`

  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then(function (response) {
        const items = response.data.data || []
        const indexedItems = items.map((item, index) => ({ ...item, index }));

        commit('setValue', { key: 'services', value: indexedItems })
        handleInclusions(commit, response.data.included)
        resolve(true)
      })
      .catch(function (error) {
        commit('notifier/showError', loadingError, { root: true })
        errorReporter.error('Error fetching services', error)
        reject(error)
      })
  })
}

export const fetchTemplates = ({ commit, dispatch, state }) => {
  return null // used in service selection but we dont need
}

export const fetchUserProfile = ({ commit, dispatch, state }) => {
  return new Promise((resolve, reject) => {
    axios
      .get('/api/v1/user_profile')
      .then(function (response) {
        commit('setValue', { key: 'userProfile', value: response.data.data })
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

const buildError = (errorMsg) => {
  return errorMsg ? [genericError, errorMsg].join('<br/>') : genericError
}

const handleInclusions = (commit, inclusions) => {
  const reducedInclusions = reduceInclusions(inclusions)
  const sortedAddOns = (reducedInclusions.service_add_on || []).sort((a, b) => {
    return a.attributes.priority - b.attributes.priority
  })

  commit('setValue', { key: 'addOns', value: sortedAddOns })
}

const reduceInclusions = (inclusions) => {
  return inclusions.reduce((summ, value) => {
    if (summ[value.type]) {
      summ[value.type].push(value)
    } else {
      summ[value.type] = [value]
    }

    return summ
  }, {})
}
