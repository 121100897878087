import { render, staticRenderFns } from "./PropertyForm.vue?vue&type=template&id=f3755e8a&scoped=true&"
import script from "./PropertyForm.vue?vue&type=script&lang=js&"
export * from "./PropertyForm.vue?vue&type=script&lang=js&"
import style0 from "./PropertyForm.vue?vue&type=style&index=0&id=f3755e8a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f3755e8a",
  null
  
)

export default component.exports