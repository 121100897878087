<template>
  <v-layout wrap id="schedule-select-date">
    <v-flex xs12 class="relative">
      <div class="loading-overlay" v-if="loading">
        <i class="fa fa-spinner fa-spin" />
      </div>

      <v-layout v-if="active" wrap>
        <v-flex xs12 md8 vpx-1 class="info-text">
          Select a date and time you'd like to schedule your {{ jobName }} for.
          <br />
          It should take approximately {{ duration(true) }}.
        </v-flex>

        <v-flex
          v-if="allowInspectorChoice && selectableInspectors.length > 2"
          xs12
          md4
          vpx-1
        >
          <v-select
            v-model="activeInspector"
            :items="selectableInspectors"
            :menu-props="{ maxHeight: '400' }"
            item-text="attributes.name"
            item-value="attributes.id"
            placeholder="Choose an Inspector"
            :attach="true"
            @input="filterOpenings"
            id="inspector-selection-dropdown"
          >
            <template slot="item" slot-scope="data">
              <v-flex md12 class="inspector-selection">
                <img
                  v-if="data.item.attributes.image"
                  :src="data.item.attributes.image"
                />
                <div class="inspector-name">{{
                  data.item.attributes.name
                }}</div>
              </v-flex>
            </template>
          </v-select>
        </v-flex>
      </v-layout>

      <div class="calendar-ref" ref="calendar" />

      <v-layout
        v-if="selectingInspector"
        wrap
        justify-center
        class="inspector-selection-popup"
      >
        <v-flex xs11 md7 class="inspector-selection-inner">
          <v-layout wrap justify-center>
            <v-flex xs12 center section class="inspector-selection-header">
              Choose {{ aStaffName }}
            </v-flex>
            <v-layout wrap justify-center class="inspector-selection-body">
              <v-flex
                xs6
                md7
                class="inspector section"
                v-for="(inspector, index) in availableInspectors"
                :key="inspector.id"
                @click="selectInspector(inspector)"
                :id="`inspector-selection-btn__${index}`"
              >
                <v-layout align-center wrap class="inspector-inner">
                  <v-flex xs12 sm3 center>
                    <img class="thumb circle" :src="inspector.thumb" />
                  </v-flex>
                  <v-flex xs12 sm9>
                    <div class="name">{{ inspector.name }}</div>
                    <div class="light"
                      >Select {{ inspector.first_name }} as your
                      {{ staffName }}.</div
                    >
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs6 md7 class="inspector section" id="inspector-selection-btn-random">
                <v-layout
                  @click="selectAnInspector"
                  align-center
                  wrap
                  class="inspector-inner"
                >
                  <v-flex xs12 sm3 center>
                    <div class="thumb circle fake-thumb">?</div>
                  </v-flex>
                  <v-flex xs12 sm9>
                    <div class="name">Choose one for me</div>
                    <div class="light"></div>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 center section>
                <div
                  @click="selectingInspector = false"
                  class="btn btn-blank btn-default btn-flat"
                  id="inspector-selection-change-date-btn"
                  >Change Date</div
                >
              </v-flex>
            </v-layout>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex xs12 center section>
      Can't find the right time? Call us at
      <a :href="'tel:' + company.attributes.phone">{{
        company.attributes.phone
      }}</a>
      or email
      <a :href="'mailto:' + company.attributes.email" target="_blank">{{
        company.attributes.email
      }}</a
      >.
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'
import { Calendar } from '@fullcalendar/core'
import axios from '../../../AxiosService'
import dayGridPlugin from '@fullcalendar/daygrid'
import dig from '../../../Dig'
import moment from 'moment'
import { invert } from 'lodash'
import indefinte from 'indefinite'

const osrmUrl = 'https://osrm.spectora.com/route/v1/driving/'

export default {
  computed: {
    ...mapState('inspection', ['charges', 'company', 'inspectors']),
    allowInspectorChoice() {
      return dig(
        this.company,
        'attributes.settings.scheduler_allow_inspector_choice',
        false
      )
    },
    aStaffName() {
      return indefinte(this.staffName)
    },
    jobName() {
      return this.company.attributes.job_name
    },
    selectableInspectors() {
      let insps = [
        { attributes: { id: 'all', name: `All ${this.staffNamePlural}` } },
      ]
      insps = insps.concat(
        this.inspectors.filter((i) => {
          return i.attributes.active && !i.attributes.settings.trainee
        })
      )
      return insps
    },
    staffName() {
      return this.company.attributes.staff_name
    },
    staffNamePlural() {
      return this.company.attributes.staff_name_plural
    },
  },
  data() {
    const parent = this

    return {
      activeInspector: 'all',
      availableInspectors: [],
      calendar: null,
      calendarConfig: {
        dayCellClassNames: ({ date }) => {
          const theDate = moment(date)
          const events = parent.calendar.getEvents()
          const hasEventsForDate = events.some((e) =>
            moment(e.start).isSame(theDate, 'day')
          )

          if (!hasEventsForDate) {
            return ['fc-day-unavailable']
          }
        },

        dayCellDidMount: (args) => {
          const { date, el } = args

          const theDate = moment(date)
          const events = parent.calendar.getEvents()
          const hasEventsForDate = events.some((e) =>
            moment(e.start).isSame(theDate, 'day')
          )

          const textElement = el.getElementsByClassName(
            'fc-daygrid-day-events'
          )[0]
          const bgElement = el.getElementsByClassName('fc-daygrid-day-frame')[0]

          bgElement.className += ' unavailable-bg'

          if (!hasEventsForDate) {
            if (parent.isMobileDevice()) {
              textElement.innerHTML = '<div class="unavailable">None</div>'
            } else {
              textElement.innerHTML =
                '<div class="unavailable">No times available.</div>'
            }
          }

          return { domNodes: [el] }
        },
        initialView: 'dayGrid2Weeks',
        eventClick: this.eventClicked,
        events: [],
        plugins: [dayGridPlugin],
        height: 'auto',
        views: {
          dayGrid2Weeks: {
            type: 'dayGrid',
            duration: { weeks: 2 },
            buttonText: '2 weeks',
          },
        },
      },
      dates: {},
      inspectorDistances: {},
      loading: false,
      selectingInspector: false,
    }
  },
  methods: {
    addNextListener() {
      let nextBtn = document.getElementsByClassName('fc-next-button')[0]
      if (nextBtn) {
        nextBtn.addEventListener('click', () => {
          this.submitToGa()
          this.fetchOpenings()
        })
      }
    },
    classDate(date) {
      return `date-${new Date(date).toISOString().replace(/(:|\.)/g, '-')}`
    },
    convertOpenings(rawOpenings) {
      let converts = rawOpenings.map((o) => {
        let dateString = o.date.toDateString()
        if (!this.dates[dateString]) this.dates[dateString] = true

        let startTime
        return o.times.map((time) => {
          startTime = moment(
            `${dateString} ${time.time}`,
            'ddd MMM D YYYY HH:mm A'
          )
          let displayTime = time.time.replace(/^(0)([1-9])/, '$2')

          let event = {
            title: displayTime,
            start: startTime.toDate(),
            editable: false,
            allDay: false,
            classNames: [this.classDate(startTime)],
            extendedProps: {
              inspectors: time.inspectors,
              datetime: [dateString, time.time].join(' '),
            },
          }

          this.setEventState(event)

          return event
        })
      })

      return [].concat.apply([], converts)
    },
    days() {
      let sortedDates = Object.keys(this.dates).sort((a, b) => {
        return moment(a, 'ddd MMM D YYYY') < moment(b, 'ddd MMM D YYYY')
          ? 1
          : -1
      })

      let date = moment(sortedDates[0], 'ddd MMM D YYYY')
      let days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]

      let moments = []

      days.forEach((d) => {
        date.add(1, 'days')
        if (this.calendar.view.activeEnd > date) moments.push(date.clone())
      })

      return moments
    },
    duration(formatted) {
      let dur = this.charges.reduce((summ, c) => {
        summ += c.duration
        return summ
      }, 0)

      if (dur === 0) dur = this.company.attributes.settings.default_duration

      if (formatted) {
        return dur === 1 ? '1 hour' : `${dur} hours`
      } else {
        return dur
      }
    },
    eventClicked(event) {
      // inspector.service_limitations = '1,2,3'
      // comma separated string of service_ids they can't perform
      // EG: '1,2,3'
      // this.serviceIds()
      // comma separated array of service_id strings selected for this booking
      // EG: ['1','2','3']
      //
      // This method ensures that the inspector can perform ALL of the services listed
      // If ANY of the services listed
      const canPerformService = (limitStr = '', serviceIds = []) => {
        // if no services or addons are selected then we can ignore it
        if (!serviceIds || serviceIds.length === 0) {
          return true
        }

        if (!limitStr || limitStr.length === 0) {
          return true
        }

        const limitations = limitStr.split(',')

        // if ALL matches are found, that means they can perform this inspection
        // a match is a service limitation
        // const allEnabled = serviceIds.every(id => limitations.includes(id))
        const allEnabled = serviceIds.every((id) => limitations.includes(id))

        return allEnabled === true
      }

      let props = event.event.extendedProps
      this.inspection.attributes.datetime = props.datetime

      const selectedInspector =
        this.activeInspector && this.activeInspector !== 'all'

      if (!selectedInspector && this.allowInspectorChoice) {
        this.selectingInspector = true
        this.availableInspectors = props.inspectors
      } else {
        const lat = dig(this.inspection, 'property.attributes.lat', '')
        const lng = dig(this.inspection, 'property.attributes.lng', '')

        if (!selectedInspector && lat && lng) {
          const capableInspectors = props.inspectors
            .filter((inspector) =>
              canPerformService(
                inspector.service_limitations,
                this.serviceIds()
              )
            )
            .filter((inspector) =>
              canPerformService(
                inspector.service_add_on_limitations,
                this.serviceAddOnIds()
              )
            )

          this.fetchDistances(capableInspectors).then(() => {
            if (this.inspectorDistances) {
              const invertedDistances = invert(this.inspectorDistances)
              const lowestDistance = Math.min(...Object.keys(invertedDistances))
              const closestId = parseInt(invertedDistances[lowestDistance])
              const closestInspector = props.inspectors.find(
                (x) => x.id === closestId
              )

              console.log('this.inspectorDistances', this.inspectorDistances)
              console.log('invertedDistances', invertedDistances)
              console.log('lowestDistance', lowestDistance)
              console.log('closestId', closestId)
              console.log('closestInspector', closestInspector)

              if (closestInspector) {
                this.inspection.inspector = closestInspector
              } else {
                this.inspection.inspector = props.inspectors[0]
              }
            } else {
              this.inspection.inspector = props.inspectors[0]
            }

            this.$emit('change')
          })
        } else {
          if (selectedInspector) {
            this.inspection.inspector = props.inspectors.find(
              (i) => i.id === this.activeInspector
            )
          } else {
            this.inspection.inspector = props.inspectors[0]
          }
          this.$emit('change')
        }
      }

      this.markEvent()
    },
    fetchDistances(inspectors) {
      const lat = dig(this.inspection, 'property.attributes.lat', '')
      const lng = dig(this.inspection, 'property.attributes.lng', '')

      if (!lat || !lng) return Promise.resolve()

      return inspectors.reduce(
        (chain, inspector) =>
          chain.then(() => {
            if (!inspector.lat || !inspector.lng) {
              return
            }
            if (!this.inspectorDistances[inspector.id]) {
              const url =
                osrmUrl + `${inspector.lng},${inspector.lat};${lng},${lat}`

              return axios
                .get(url)
                .then((response) => {
                  if (
                    response.data.code === 'Ok' &&
                    response.data.routes &&
                    response.data.routes.length > 0
                  ) {
                    this.inspectorDistances[inspector.id] =
                      response.data.routes[0].distance
                  } else {
                    this.inspectorDistances[inspector.id] = 1000
                  }
                })
                .catch((error) => {
                  console.log(error)
                  this.inspectorDistances[inspector.id] = 1000
                })
            }
          }),
        Promise.resolve()
      )
    },
    fetchOpenings() {
      if (this.loading) {
        console.log('already loading!!!')
        return
      }

      this.loading = true

      let requests = this.openingUrls().map((url) => {
        return axios.get(url)
      })

      return Promise.all(requests).then((response) => {
        let newOpenings = this.days().map((d, i) => {
          return {
            date: d.toDate(),
            times: response[i].data,
          }
        })

        if (newOpenings.length > 0) {
          let newEvents = this.convertOpenings(newOpenings)
          this.calendarConfig.events =
            this.calendarConfig.events.concat(newEvents)
          this.calendar.batchRendering(() => {
            newEvents.forEach((e) => {
              this.calendar.addEvent(e)
            })
          })
        }
        // Have fullcalender rerender. This is the way to do this for now.
        this.calendar.prev()
        this.calendar.next()

        this.loading = false
      })
    },
    filterOpenings() {
      if (this.activeInspector === 'all') {
        this.inspection.inspectorRequested = false
      } else {
        this.inspection.inspectorRequested = true
      }

      this.calendarConfig.events.forEach((e) => {
        this.setEventState(e)
      })

      this.loading = true
      this.teardownCalendar()
      let ctx = this
      setTimeout(() => {
        ctx.renderCalendar()
        ctx.renderCalendar()
        ctx.addNextListener()
        ctx.loading = false
      }, 100)
    },
    markEvent() {
      Array.from(document.getElementsByClassName('fc-event selected')).forEach(
        (el) => {
          el.classList.remove('selected')
        }
      )

      let eventEl = document.getElementsByClassName(
        this.classDate(this.inspection.attributes.datetime)
      )[0]
      if (eventEl) eventEl.classList.add('selected')
    },
    serviceIds() {
      return dig(this.inspection, 'services', []).map((s) => {
        return s.id
      })
    },
    serviceAddOnIds() {
      return dig(this.inspection, 'add_ons', []).map((ao) => {
        return ao.id
      })
    },
    openingUrls() {
      let lat = dig(this.inspection, 'property.attributes.lat', '')
      let lng = dig(this.inspection, 'property.attributes.lng', '')
      let duration = this.duration(false)
      let serviceIds = dig(this.inspection, 'services', []).map((s) => {
        return s.id
      })
      let addOnIds = dig(this.inspection, 'add_ons', []).map((ao) => {
        return ao.id
      })

      serviceIds =
        serviceIds.length > 0 ? serviceIds.join('&service_id[]=') : null
      addOnIds =
        addOnIds.length > 0 ? addOnIds.join('&service_add_on_id[]=') : null

      return this.days().map((d) => {
        let date = d.format('YYYY-M-D')
        let url = `/time_slots/open/${this.company.id}/${date}?duration=${duration}&lng=${lng}&lat=${lat}`
        if (serviceIds) url += `&service_id[]=${serviceIds}`
        if (addOnIds) url += `&service_add_on_id[]=${addOnIds}`
        return url
      })
    },
    renderCalendar() {
      if (this.calendar) {
        this.calendar.render()
        if (this.inspection.attributes.datetime) {
          this.markEvent()
        }
      } else {
        let cal = this.$refs.calendar
        this.calendar = new Calendar(cal, this.calendarConfig)
      }
    },
    selectAnInspector() {
      this.inspection.inspector =
        this.availableInspectors[
          Math.floor(Math.random() * this.availableInspectors.length)
        ]
      this.$emit('change')
    },
    selectInspector(inspector) {
      this.inspection.inspector = inspector
      this.$emit('change')
    },
    setEventState(event) {
      let hideCount = 0
      event.extendedProps.inspectors.forEach((i) => {
        i.hidden =
          this.activeInspector === 'all' ? false : i.id !== this.activeInspector
        if (i.hidden) hideCount += 1
      })

      if (hideCount === event.extendedProps.inspectors.length) {
        event.classNames.push('hidden')
      } else {
        event.classNames = event.classNames.filter((cn) => {
          return cn !== 'hidden'
        })
      }
    },
    submitToGa() {
      if (typeof ga === 'function') {
        ga('send', 'event', 'click', 'buttn', 'scheduler_calendar_forward') // eslint-disable-line
      }
    },
    teardownCalendar() {
      if (this.calendar) this.calendar.destroy()
      this.calendar = null
    },
    isMobileDevice() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true
      } else {
        return false
      }
    },
  },
  mounted() {
    if (
      dig(this.company, 'attributes.settings.scheduler_allow_inspector_choice')
    ) {
      this.$store.dispatch('inspection/fetchInspectors')
    }
  },
  props: ['active', 'inspection', 'params'],
  watch: {
    active(newV) {
      if (newV) {
        let ctx = this
        this.selectingInspector = false
        this.inspection.inspector = null
        this.inspection.attributes.datetime = null
        setTimeout(() => {
          ctx.renderCalendar()
          ctx.addNextListener()
        }, 100)
      } else {
        this.teardownCalendar()
      }
    },
    params: {
      handler: function (newV) {
        this.loading = true
        this.dates = {}
        let openings = dig(newV, 'openings', [])
        if (openings.length > 0) {
          this.teardownCalendar()
          this.calendarConfig.events = this.convertOpenings(openings)
          this.renderCalendar()
          let ctx = this
          setTimeout(() => {
            ctx.renderCalendar()
            ctx.addNextListener()
            ctx.loading = false
          }, 100)
        }
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.calendar-ref {
  min-height: 10em;
}

.info-text {
  font-size: 1.25em;
}

.inspector-selection {
  font-size: 1em;
  color: #45769c;
  display: flex;
  align-items: center;
}

.inspector-selection .inspector-name,
.inspector-selection img {
  display: inline-block;
}

.inspector-selection .inspector-name {
  line-height: 2em;
}

.inspector-selection img {
  height: 2em;
  width: 2em;
  margin-right: 1em;
  border-radius: 50%;
}

.inspector-selection-popup {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.7);
  align-items: flex-start;
  align-content: center;

  .inspector-selection-header {
    color: #fff;
    font-size: 1.25em;
    padding: 1rem;
    background-color: #5c9ccf;
    border-bottom: 5px solid #45769c;
    font-size: 2em;
    font-weight: 300;
  }

  .inspector-selection-inner {
    background-color: #fff;
    box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22),
      0 25px 55px 0 rgba(0, 0, 0, 0.21);
  }

  .inspector-selection-body {
    padding: 2em;
    overflow: scroll;
    height: 450px;
  }

  .inspector {
    cursor: pointer;
    background-color: #fff;
    transition: background 0.1s linear;
    border: 1px solid #d9d9d9;
    margin-top: 1em;
  }

  .inspector:hover {
    background-color: #f9f9f9;
  }
}

.btn {
  margin-bottom: 0;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4em;
  color: #777;
}

.relative {
  position: relative;
}

.thumb {
  margin: 0;
}

.fake-thumb {
  height: 50px;
  width: 50px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  background: #eee;
  color: #ccc;
  font-weight: bold;
  font-size: 2em;
}

@media (max-width: 800px) {
  .inspector-selection {
    overflow-y: auto;
  }
}

@media (max-width: 600px) {
  .inspector-inner {
    text-align: center;
  }
}
</style>

<style lang="scss">
#schedule-select-date {
  td.fc-day-today {
    background: #97ea6e;
    .fc-daygrid-day-top {
      font-weight: bold !important;
    }
    &.fc-day-unavailable {
      background: repeating-linear-gradient(
        -45deg,
        #f1f1f1,
        #f1f1f1 10px,
        #97ea6e 10px,
        #97ea6e 20px
      );
      font-size: 16px;
      font-weight: normal !important;
    }
  }

  .fc-daygrid-day-top {
    color: #666;
  }

  .fc-daygrid-event-dot {
    display: none;
  }
  .fc-day-past {
    background-color: #fafafa;
  }
  .fc-daygrid-day .fc-daygrid-day-frame {
    min-height: 150px !important;
  }

  .fc-event {
    background: #fff;
    border: 2px solid #5c9ccf;
    transition: background 0.1s linear;
    display: flex;
    align-items: center;
    justify-content: center;
    display: block;
    text-align: center;
    .fc-event-time {
      display: none;
    }
    .fc-event-title {
      color: #5c9ccf;
      font-weight: 500;
      font-size: 1.5em;
      transition: color 0.1s linear;

      @media only screen and (max-width: 992px) {
        font-size: 1em;
        white-space: break-spaces;
      }
    }

    &.hidden {
      display: none;
    }

    &:hover {
      background: #5c9ccf;
      .fc-event-title {
        color: #fff;
      }
    }

    &.selected {
      background: #45769c;
      .fc-event-title {
        color: #fff;
      }
    }
  }

  @media (max-width: 800px) {
    .fc .fc-header-toolbar {
      flex-wrap: wrap;

      .fc-left,
      .fc-right {
        float: none;
        width: 100%;
        text-align: center;
      }
      .fc-left *,
      .fc-right * {
        float: none;
      }
    }

    .fc .fc-event {
      min-height: 4em;

      .fc-title {
        font-size: 1.1em;
      }
    }

    .fc .fc-content-skeleton table {
      border-collapse: separate;
      border-spacing: 0 5px;
    }
  }
}

.unavailable {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0px 20px;
}

.fc-day-unavailable {
  background: repeating-linear-gradient(
    -45deg,
    #fdfdfd,
    #fdfdfd 10px,
    #f1f1f1 10px,
    #f1f1f1 20px
  );
}
</style>
