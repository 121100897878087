<template>
  <v-layout wrap id="schedule-review-inspection">
    <template v-if="active && !params.saved">
      <v-flex xs12 section>
        <v-layout>
          <v-flex xs12>
            {{ address1 }}
            <br />
            {{ address2 }}
            <div class="inspector-row">
              with
              <img class="thumb circle" :src="inspectorImage" />
              {{ inspectorName }}
            </div>
          </v-flex>
          <v-flex xs12 align-right>
            {{ date }}
            <br />
            {{ time }}
            <br />
            {{ duration }}
          </v-flex>
        </v-layout>
      </v-flex>
      <v-layout wrap class="services section">
        <v-flex xs12>
          <h3>
            <i class="fa fa-file-o" />
            SERVICES
          </h3>
        </v-flex>
        <template>
          <v-layout
            full-width
            class="line-item"
            v-for="service in serviceCharges()"
            :key="service.name + service.amount"
          >
            <v-flex xs6>
              {{ service.name }}
              <div
                v-if="displayServiceInfo"
                class="light small"
                v-html="serviceNote(service)"
              />
            </v-flex>
            <v-flex v-if="displayPricing" xs6 align-right
              >{{ company.attributes.currency_sym
              }}{{ displayCost(service.pretax_amount) }}</v-flex
            >
          </v-layout>
          <v-layout
            full-width
            class="line-item"
            v-for="addon in addOnCharges()"
            :key="addon.name + addon.amount"
          >
            <v-flex xs6>
              {{ addon.name }}
              <div
                v-if="displayServiceInfo"
                class="light small"
                v-html="addOnNote(addon)"
              />
            </v-flex>
            <v-flex v-if="displayPricing" xs6 align-right
              >{{ company.attributes.currency_sym
              }}{{ displayCost(addon.pretax_amount) }}</v-flex
            >
          </v-layout>
          <v-layout
            v-if="hasTax && displayPricing"
            full-width
            class="line-item"
          >
            <v-flex xs6>
              TAX
              <div class="light small" v-html="taxNotes" />
            </v-flex>
            <v-flex xs6 align-right
              >{{ company.attributes.currency_sym
              }}{{ displayCost(taxSum) }}</v-flex
            >
          </v-layout>
        </template>

        <v-layout wrap v-if="displayPricing">
          <div>
            <v-flex xs6 class="total-cell"><strong>Total</strong></v-flex>
            <v-flex xs6 align-right class="total-cell"
              ><strong
                >{{ company.attributes.currency_sym
                }}{{ displayCost(total) }}</strong
              >
            </v-flex>
          </div>

          <div class="bnpl" v-if="bnplEnabled && stripeKey">
            <stripe-elements :stripeKey="stripeKey" #default="{ elements }">
              <stripe-element
                type="paymentMethodMessaging"
                :elements="elements"
                :options="paymentMethodMessagingOptions"
              />
            </stripe-elements>
          </div>
        </v-layout>
      </v-layout>
      <v-flex
        v-if="this.company.attributes.has_discounts"
        offset-md10
        offset-xs6
        xs6
        md2
      >
        <v-text-field
          label="Discount Code"
          v-model="inspection.attributes.discount_code"
          @keyup="discount"
          id="review-inspection-discount-code"
        >
          <template slot="prepend-inner">
            <i v-if="!discounting" class="fa fa-ticket discount-icon" />
            <i v-else class="fa fa-spinner fa-spin discount-icon" />
          </template>
        </v-text-field>
      </v-flex>

      <v-layout wrap full-width>
        <v-flex xs12 md4>
          <v-text-field
            v-if="!restrictReferalSources"
            v-model="inspection.attributes.referral_source"
            label="How did you hear about us?"
          />
          <v-select
            v-if="restrictReferalSources"
            v-model="inspection.attributes.referral_source"
            :items="referralSources"
            label="How did you hear about us?"
            id="review-inspection-referral-source-dropdown"
          />
        </v-flex>
        <v-flex
          xs12
          v-if="company.attributes.settings.scheduler_show_request_notes"
        >
          <v-textarea
            v-model="inspection.attributes.request_notes"
            label="Anything else you'd like us to know?"
            placeholder=" (I.E. lockbox code, entrance instructions, special considerations, etc.)"
          />
        </v-flex>
      </v-layout>
    </template>
    <template v-else-if="active && params.saved">
      <v-flex xs12 center>
        <h3>
          <i class="fa fa-check-circle" />
          We've received your {{ jobName }} Request!
        </h3>
      </v-flex>
      <v-flex xs12 center>
        <div
          class="custom-content"
          v-html="company.attributes.settings.scheduler_confirmation_text"
        />
      </v-flex>
    </template>
  </v-layout>
</template>

<script>
import { debounce } from 'lodash'
import { mapState } from 'vuex'
import dig from '../../../Dig'
import moment from 'moment'
import { StripeElements, StripeElement } from 'vue-stripe-elements-plus'

export default {
  components: {
    StripeElements,
    StripeElement,
  },
  computed: {
    ...mapState('inspection', ['charges', 'company']),
    ...mapState('inspection', {
      savedInspection: 'inspection',
    }),
    address1() {
      return `${this.property.attributes.address} ${
        this.property.attributes.unit || ''
      }`
    },
    address2() {
      const cityOrCounty =
        this.property.attributes.city || this.property.attributes.county
      const cityState = [cityOrCounty, this.property.attributes.state]
        .filter((i) => i)
        .join(', ')
      return `${cityState} ${this.property.attributes.zip}`
    },
    date() {
      return this.datetime.toLocaleDateString()
    },
    displayServiceInfo() {
      return this.company.attributes.settings.scheduler_show_pricing_details
    },
    displayPricing() {
      return !this.company.attributes.settings.scheduler_hide_pricing
    },
    duration() {
      let dur = this.charges.reduce((summ, c) => {
        summ += c.duration
        return summ
      }, 0)

      if (dur === 0) {
        return ''
      } else if (dur === 1) {
        return '1 hour'
      } else {
        return `${dur} hours`
      }
    },
    hasTax() {
      return !!this.charges.find((c) => {
        return c.tax > 0.0
      })
    },
    inspectorImage() {
      return this.inspector.thumb
    },
    inspectorName() {
      return this.inspector.name
    },
    jobName() {
      return this.company.attributes.job_name
    },
    property() {
      return this.inspection.property
    },
    restrictReferalSources() {
      return this.company.attributes.settings.restrict_referral_sources
    },
    referralSources() {
      return (this.company.attributes.settings.referral_sources || '')
        .split(',')
        .map((referralSource) => referralSource.trim())
    },
    taxNotes() {
      let notes = this.charges.reduce((summ, c) => {
        summ += c.tax_notes
        return summ
      }, '')

      return notes.replace(/(?:\r\n|\r|\n)/g, '<br />')
    },
    taxSum() {
      return this.charges.reduce((summ, c) => {
        summ += parseFloat(c.tax)
        return summ
      }, 0.0)
    },
    time() {
      let times = this.datetime.toLocaleTimeString().split(':')
      return `${times[0]}:${times[1]}${times[2].replace(/[0-9]+/, '')}`
    },
    total() {
      return this.charges.reduce((summ, c) => {
        summ += parseFloat(c.amount)
        return summ
      }, 0.0)
    },
    paymentMethodMessagingOptions() {
      let paymentMethodTypes = []

      if (this.company.attributes.settings.enable_bnpl_affirm) {
        paymentMethodTypes.push('affirm')
      }

      if (this.company.attributes.settings.enable_bnpl_afterpay) {
        paymentMethodTypes.push('afterpay_clearpay')
      }

      return {
        amount: Math.round(this.total * 100),
        currency: (this.company.attributes.currency || 'usd').toUpperCase(),
        paymentMethodTypes: paymentMethodTypes,
        countryCode: 'US',
      }
    },
    bnplEnabled() {
      return (
        this.company.attributes.settings.enable_bnpl &&
        this.company.attributes.settings.enable_bnpl_for_all_inspections &&
        (this.company.attributes.settings.enable_bnpl_affirm ||
          this.company.attributes.settings.enable_bnpl_afterpay)
      )
    },
    stripeKey() {
      return window.stripePublicKey
    },
  },
  created() {
    this.debounceDiscount = debounce(() => {
      this.$store
        .dispatch('inspection/calculateCharges', this.inspection)
        .then(() => {
          this.setDiscountStatus()
          this.discounting = false
        })
    }, 500)
  },
  data() {
    return {
      datetime: null,
      discounted: false,
      discounting: false,
      inspector: null,
    }
  },
  props: ['active', 'inspection', 'params'],
  methods: {
    addOnCharges() {
      return dig(this.inspection, 'add_ons', []).map((ao) => {
        return this.charges.find((c) => {
          return c.service_add_on_id === ao.attributes.id
        })
      })
    },
    addOnNote(addOn) {
      return addOn.notes.replace(/(?:\r\n|\r|\n)/g, '<br />')
    },
    discount() {
      if (!this.discounting) {
        this.discounting = true
        this.debounceDiscount()
      }
    },
    displayCost(amount) {
      return parseFloat(amount).toFixed(2)
    },
    parseInspectionDatetime() {
      let dt = this.inspection.attributes.datetime
      dt = moment(dt, 'ddd MMM D YYYY HH:mm A')
      return dt.toDate()
    },
    serviceCharges() {
      return this.inspection.services.map((s) => {
        return this.charges.find((c) => {
          return c.service_id === s.attributes.id
        })
      })
    },
    serviceNote(service) {
      return service.notes.replace(/(?:\r\n|\r|\n)/g, '<br />')
    },
    setDiscountStatus() {
      if (!this.discounted) {
        let discountedCharge = this.charges.find((c) => {
          return c.discount_amount
        })
        this.discounted = !!discountedCharge
        if (discountedCharge) {
          let discountMsg
          if (this.displayPricing) {
            discountMsg = `Your discount code has saved you $${parseFloat(
              discountedCharge.discount_amount
            ).toFixed(2)} on ${discountedCharge.name}!`
          } else {
            discountMsg = 'Your discount code was applied!'
          }

          if (this.discounted)
            this.$store.dispatch('notifier/show', {
              type: 'Saved',
              msg: discountMsg,
            })
        } else {
          this.$store.dispatch('notifier/show', {
            type: 'Warning',
            msg: "The discount code you entered isn't valid.",
          })
        }
      } else {
        this.discounted = !!this.charges.find((c) => {
          return c.discount_amount
        })
        this.$store.dispatch('notifier/show', {
          type: 'Warning',
          msg: "The discount code you entered isn't valid.",
        })
      }
    },
  },
  watch: {
    active(newV) {
      if (newV) {
        this.datetime = this.parseInspectionDatetime()
        this.inspector = this.inspection.inspector
      } else {
        this.datetime = null
        this.inspector = null
      }
    },
    params: {
      handler: function (newV) {
        if (this.active && newV.saved) {
          let html = ''
          if (this.inspection.attributes.confirmed) {
            this.inspectionLink = this.savedInspection.client_url
            html = `<div class='center-align margin-top'><a target='_blank' href='${this.savedInspection.client_url}'><div class='btn btn-primary'>Inspection Details</div></a></div>`
          }

          let newText =
            this.company.attributes.settings.scheduler_confirmation_text.replace(
              '{{INSPECTION_LINK}}',
              html
            )
          this.company.attributes.settings.scheduler_confirmation_text = newText
        }
      },
      deep: true,
    },
  },
}
</script>

<style scoped>
.align-right {
  text-align: right;
}

.custom-content {
  width: auto;
  font-size: 1.1em;
  display: inline-block;
}

.discount-icon {
  margin-top: 5px;
}

.full-width {
  width: 100%;
}

.inspector-row {
  display: flex;
  align-content: center;
}

.line-item {
  margin-bottom: 0.5em;
}

.services {
  font-size: 1.1em;
}

.thumb {
  margin: 0 0.5em;
  height: 1.5em;
  width: 1.5em;
}

.total-cell {
  margin-top: 0.25em;
}

.bnpl {
  margin-left: 35px;
  margin-top: 10px;
}
</style>

<style>
#schedule-review-inspection textarea {
  text-indent: 0.25em;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
</style>
