import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'

const errorReporter = {
  error: (message, exception) => {
    Sentry.captureException(exception)
  },
  init() {
    Sentry.init({
      dsn: 'https://8749ef781ac640939e0cd26be2c5ef24@o877009.ingest.sentry.io/5831089',
      integrations: [new Integrations.BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.02,
      beforeSend: function (event) {
        // Modify the event here
        // Return null to drop the event completely
        console.log('Sentry Event: ', event)

        if (
          event.exception &&
          event.exception.values &&
          event.exception.values[0]
        ) {
          const expectionData = event.exception.values[0]
          if (
            expectionData.type === 'TypeError' &&
            expectionData.value ===
              `Cannot read properties of undefined (reading '0')`
          ) {
            console.log(`Error ${expectionData.value} was ignored`)
            return null
          }
        }
        return event
      },
    })
  },
}

errorReporter.init()

export default errorReporter
