<template>
  <v-layout wrap service-selector service-selection-component>
    <v-flex xs12 vpx-1 :class="displayClasses">
      <v-select
        v-model="serviceIds"
        :items="services"
        :menu-props="menuProps"
        item-text="attributes.name"
        item-value="attributes.id"
        data-cy="inspection_service_select"
        placeholder="Select Services"
        @input="toggleSelection"
        :attach="true"
        multiple
        id="service-selection-dropdown"
      >
        <template slot="item" slot-scope="data">
          <v-flex
            xs12
            class="service-selection"
            :id="`service-item__${data.item.index}`"
          >
            <v-flex xs2 md1>
              <v-checkbox
                v-model="data.item.selected"
                :id="`service-checkbox__${data.item.attributes.name.replace(
                  /[^a-zA-Z0-9]/g,
                  ''
                )}`"
              />
            </v-flex>
            <v-layout wrap class="name name-and-description">
              <div>{{ data.item.attributes.name }}</div>
              <div class="light small">{{
                data.item.attributes.description
              }}</div>
            </v-layout>
          </v-flex>
        </template>
      </v-select>
    </v-flex>
    <template v-for="serviceId in serviceIds">
      <v-flex
        xs12
        vpx-1
        :class="displayClasses"
        v-if="hasAddOns[serviceId]"
        :key="serviceId"
      >
        <v-select
          v-model="addOnIds"
          :items="addOnsForService[serviceId]"
          :menu-props="menuProps"
          item-text="attributes.name"
          item-value="attributes.id"
          placeholder="Select Add Ons"
          @input="toggleAddOnSelection"
          :label="addOnLabel(serviceId)"
          :attach="true"
          multiple
        >
          <template slot="item" slot-scope="data">
            <v-flex xs12 class="service-selection">
              <v-flex xs1>
                <v-checkbox
                  v-model="data.item.selected"
                  :id="`service-addon-checkbox__${data.item.attributes.name.replace(
                    /[^a-zA-Z0-9]/g,
                    ''
                  )}`"
                />
              </v-flex>
              <v-layout wrap class="name name-and-description">
                <div>{{ data.item.attributes.name }}</div>
                <div class="light small">{{
                  data.item.attributes.description
                }}</div>
              </v-layout>
            </v-flex>
          </template>
        </v-select>
      </v-flex>
    </template>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('inspection', [
      'addOns',
      'agreements',
      'company',
      'services',
      'templates',
    ]),
    addOnsForService() {
      return this.addOns.reduce((summ, ao) => {
        if (summ[ao.attributes.service_id]) {
          summ[ao.attributes.service_id].push(ao)
        } else {
          summ[ao.attributes.service_id] = [ao]
        }
        return summ
      }, {})
    },
    defaultMenuProps() {
      return { maxHeight: '400' }
    },
    displayClasses() {
      return this.display === 'rows' ? '' : 'sm6 md4'
    },
    hasAddOns() {
      return this.addOns.reduce((summ, ao) => {
        summ[ao.attributes.service_id] = true
        return summ
      }, {})
    },
  },
  data() {
    let info = {
      addOnIds: [],
      serviceIds: [],
    }

    if (this.initialServiceIds) {
      info.serviceIds = [...this.initialServiceIds]
    }

    if (this.initialAddOnsIds) {
      info.addOnIds = [...this.initialAddOnsIds]
    }

    return info
  },
  props: {
    display: { default: 'rows' },
    initialAddOnsIds: {
      type: Array,
      default: () => [],
    },
    loadResources: { default: false },
    menuProps: {
      function() {
        return { maxHeight: '400' }
      },
    },
    initialServiceIds: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    addOnLabel(serviceId) {
      let name = this.services.find((s) => s.attributes.id === serviceId)
        .attributes.name
      return `${name} Options`
    },
    setupPage() {
      this.$store.dispatch('inspection/fetchServices')
      this.$store.dispatch('inspection/fetchTemplates')
      this.$store.dispatch('inspection/fetchAgreements')
    },
    toggleAddOnSelection(e) {
      this.addOns.forEach((ao) => {
        ao.selected = e.includes(ao.attributes.id)
      })

      this.$emit('addonChange', e)
    },
    toggleSelection(e) {
      let emitAddons = false

      this.services.forEach((s) => {
        s.selected = e.includes(s.attributes.id)
        if (!s.selected) {
          this.addOns.forEach((ao) => {
            if (ao.attributes.service_id === s.attributes.id) {
              ao.selected = false
              this.addOnIds = this.addOnIds.filter(
                (aoId) => aoId !== ao.attributes.id
              )
              emitAddons = true
            }
          })
        }
      })

      if (emitAddons) this.$emit('addonChange', this.addOnIds)
      this.$emit('serviceChange', e)
    },
  },
  mounted() {
    if (this.loadResources) this.setupPage()
    if (this.draftServiceIds) {
      this.services.forEach((s) => {
        s.selected = this.draftServiceIds.includes(s.attributes.id)
      })
      this.serviceIds = this.draftServiceIds
    }

    if (this.draftAddOnsIds) {
      this.addOns.forEach((ao) => {
        if (this.draftAddOnsIds.includes(ao.attributes.id)) {
          ao.selected = true
        }
        this.addOnIds = this.draftAddOnsIds
      })
    }
  },
  watch: {
    initialServiceIds: {
      deep: true,
      handler: function (newV, oldV) {
        this.serviceIds = [...newV]
        this.services.forEach((service) => {
          service.selected = this.serviceIds.includes(service.attributes.id)
        })
      },
    },
    initialAddOnsIds: {
      deep: true,
      handler: function (newV, oldV) {
        this.addOnIds = [...newV]
        this.addOns.forEach((addOn) => {
          addOn.selected = this.addOnIds.includes(addOn.attributes.id)
        })
      },
    },
  },
}
</script>

<style scoped>
.service-selection {
  font-size: 1em;
  color: #45769c;
  display: flex;
  align-items: center;
}

.service-selection div {
  display: inline-block;
}

.service-selector .service-selection .name {
  margin-left: 0.5em;
}
.service-selector .service-selection .name-and-description {
  padding: 0.5em 0;
}
</style>

<style lang="scss">
.service-selection-component {
  .v-list__tile--link {
    height: auto;
    min-height: 48px;
  }

  .v-input--checkbox {
    margin-top: 5px;
  }
  .v-input--checkbox .v-input__slot {
    margin: 0;
  }
}
</style>
