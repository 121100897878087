import 'vuetify/dist/vuetify.min.css'
import 'font-awesome/css/font-awesome.css'
// import '@fullcalendar/core/main.css'
// import '@fullcalendar/daygrid/main.css'

import Vue from 'vue'
import Vuetify from 'vuetify'
import VueMeta from 'vue-meta'
import VueMoment from 'vue-moment'
import VeeValidate from 'vee-validate'
import VueRouter from 'vue-router'
import * as VueGoogleMaps from 'vue2-google-maps'
import $ from 'jquery'

import Schedule from '../components/inspection/Schedule'
import store from '../store/schedule'

Vue.use(Vuetify, {
  theme: {
    primary: '#5c9ccf',
    secondary: '#45769c',
    error: '#d53636',
    info: '#65c635',
    success: '#65c635',
    warning: '#d53636',
    orange: '#f9890e',
  },
})
Vue.use(VeeValidate)
Vue.use(VueMeta)
Vue.use(VueMoment)
Vue.use(VueRouter)
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.GOOGLE_MAPS_API_KEY,
    libraries: 'places',
  },
})

const routes = [
  {
    name: 'schedule',
    path: '/home-inspectors/:companySlug/schedule',
    component: Schedule,
  },
  {
    name: 'scheduleStep',
    path: '/home-inspectors/:companySlug/schedule/:step',
    component: Schedule,
  },
]

const router = new VueRouter({ mode: 'history', routes: routes })

document.addEventListener('DOMContentLoaded', () => {
  const el = document.body.appendChild(
    document.createElement('schedule-inspection')
  )
  // eslint-disable-next-line no-unused-vars
  const app = new Vue({
    el,
    store,
    router,
    render: (h) => h(Schedule),
  })
})

$(document).ready(() => {
  $('.show-more-inspectors').on('click', function () {
    $(this).hide()
    $('.more-inspectors').slideDown()
  })
})
