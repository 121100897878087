<template>
  <div class="agent-search-container" :id="id">
    <transition-expand>
      <v-layout align-center>
        <v-flex
          v-if="searchMode"
          vpx-1
          class="agent-search"
          :class="{ 'xs8 sm10': inline, xs12: !inline }"
        >
          <autocomplete-search
            :url="searchUrl"
            :queryParams="queryParams"
            placeholder="Search for an Agent"
            selectionText="name"
            @click="resultClicked"
            :id="`agent-search-autocomplete__${agentIndex}`"
          >
            <template slot="result" slot-scope="result">
              <div class="agent-result">
                <img class="circle" :src="result.result.thumb" />
                <div class="name-combo">
                  <div class="name">{{ result.result.name }}</div>
                  <div v-if="result.result.agency_name" class="small light">
                    {{ result.result.agency_name }}
                  </div>
                </div>
              </div>
            </template>
            <template slot="appendResults">
              <li @click="toggleMode" class="light"
                >Not here? Click to add a new Agent</li
              >
            </template>
            <template slot="noResults">
              <div class="light">
                No results found. Click to add a new Agent
              </div>
            </template>
          </autocomplete-search>
          <div
            v-if="!inline"
            class="btn btn-flat btn-small btn-blank add-selling-agent"
            @click="toggleMode"
            :id="`enable-agent-form-mode__${agentIndex}`"
            >AGENT FORM</div
          >
        </v-flex>
        <v-flex v-if="inline && searchMode" xs4 sm2 vpx-1 center>
          <div @click="toggleMode" class="small fg-blue pointer">
            <i>Not here? Click to add a new Agent</i>
          </div>
        </v-flex>
      </v-layout>
    </transition-expand>
    <transition-expand>
      <div v-if="!searchMode" class="agent-form">
        <v-layout wrap>
          <v-flex md6 vpx-1>
            <v-text-field
              v-model="agent.attributes.first_name"
              label="First Name"
              :id="`agent-first-name__${agentIndex}`"
            />
          </v-flex>
          <v-flex md6 vpx-1>
            <v-text-field
              v-model="agent.attributes.last_name"
              label="Last Name"
              :id="`agent-last-name__${agentIndex}`"
            />
          </v-flex>
          <v-flex md6 vpx-1>
            <v-text-field
              v-model="agent.attributes.email"
              label="Email"
              :rules="[rules.validEmail]"
              :id="`agent-email__${agentIndex}`"
            />
          </v-flex>
          <v-flex v-if="!hideCCEmail" md6 vpx-1>
            <v-text-field
              v-model="agent.attributes.cc_email"
              label="CC Email"
              :rules="[rules.validCCEmail]"
              :id="`agent-cc-email__${agentIndex}`"
            />
          </v-flex>
          <v-flex md6 vpx-1>
            <v-text-field
              v-model="agent.attributes.phone"
              label="Phone"
              @input="formatPhoneNumber"
              :id="`agent-phone__${agentIndex}`"
            />
          </v-flex>
          <v-flex md6 vpx-1>
            <v-combobox
              v-model="agent.attributes.agency_name"
              :items="agencyAutocompleteItems"
              :loading="agencyAutocompleteLoading"
              :search-input.sync="agencyAutocompleteSearch"
              label="Agency Name"
              :id="`agent-agency-name__${agentIndex}`"
            />
          </v-flex>
          <v-flex xs12 v-if="captureAddress">
            <v-text-field
              v-model="agent.attributes.address"
              label="Address"
              :id="`agent-address__${agentIndex}`"
            />
          </v-flex>
          <v-layout v-if="captureAddress">
            <v-flex xs7 vpx-1>
              <v-text-field
                v-model="agent.attributes.city"
                label="City"
                :id="`agent-city__${agentIndex}`"
              />
            </v-flex>
            <v-flex xs2 vpx-1>
              <v-text-field
                v-model="agent.attributes.state"
                label="State"
                :id="`agent-state__${agentIndex}`"
              />
            </v-flex>
            <v-flex xs3 vpx-1>
              <v-text-field
                v-model="agent.attributes.zip"
                label="Zip"
                :id="`agent-zip__${agentIndex}`"
              />
            </v-flex>
          </v-layout>
          <v-flex xs6 vpx-1 class="file-picker-container">
            <template v-if="displayImage">
              <file-picker
                accept="image/jpg,image/jpeg,image/png,image/gif"
                @change="filePicked"
              >
                <div
                  slot="picker"
                  slot-scope="slotProps"
                  @click="slotProps.pickFunction"
                  class="field-image-container"
                >
                  <v-text-field
                    :readonly="true"
                    placeholder="Click here to add a new photo"
                  />
                  <img
                    accept="image/jpg,image/jpeg,image/png,image/gif"
                    :src="imageUrl"
                    class="circle agent-image"
                    :id="`agent-photo__${agentIndex}`"
                  />
                </div>
              </file-picker>
            </template>
            <file-picker
              v-else
              accept="image/jpg,image/jpeg,image/png,image/gif"
              placeholder="Image"
              @change="filePicked"
              :id="`agent-photo-2__${agentIndex}`"
            />
          </v-flex>
          <v-flex v-if="autotracks" xs6 vpx-1 class="tagger-container">
            <tagger
              ref="tagger"
              v-model="agent.attributes.tag_string"
              tagType="connection"
              :companyId="company.id"
            />
          </v-flex>
          <v-flex xs12 sm6 vpx-1 notes-field>
            <v-textarea label="Notes" v-model="agent.attributes.notes">
            </v-textarea>
          </v-flex>
          <v-flex
            xs12
            sm6
            vpx-1
            notes-field
            v-if="userProfile.attributes.is_company_admin"
            :id="`agent-notes__${agentIndex}`"
          >
            <v-textarea
              label="Private Notes"
              v-model="agent.attributes.private_notes"
              :id="`agent-private-notes__${agentIndex}`"
            >
            </v-textarea>
          </v-flex>
        </v-layout>
        <div
          class="btn btn-flat btn-small btn-blank add-selling-agent"
          @click="toggleMode"
          :id="`enable-agent-search-mode__${agentIndex}`"
        >
          <div class="fa fa-search" />
          SEARCH FOR AN AGENT
        </div>
      </div>
    </transition-expand>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from '../../AxiosService'
import AutocompleteSearch from '../shared/AutocompleteSearch'
import FilePicker from '../shared/FilePicker'
import Tagger from './Tagger'
import TransitionExpand from './TransitionExpand'

import { formatPhone } from '@spectora/utils'

export default {
  components: {
    AutocompleteSearch,
    FilePicker,
    Tagger,
    TransitionExpand,
  },
  computed: {
    ...mapState('inspection', ['company', 'userProfile']),
    autotracks() {
      return (
        this.company.attributes.settings.trial_auto_tracks ||
        this.company.attributes.settings.enable_auto_tracks
      )
    },
    displayImage() {
      return !!this.agent.attributes.image_url
    },
    imageUrl() {
      return this.agent.attributes.image || this.agent.attributes.image_url
    },
    searchMode() {
      return this.mode === 'search'
    },
    searchUrl() {
      return `/connection_search`
    },
    queryParams() {
      return {
        company_id: this.company.id,
      }
    },
  },
  data() {
    return {
      agencyAutocompleteItems: [],
      agencyAutocompleteLoading: false,
      agencyAutocompleteSearch: '',
      agent: {
        attributes: {},
      },
      mode: 'search',
      rules: {
        validEmail: (value) =>
          !!(value || '').match(/^[^@\s]+@[^@\s]+$/) || 'This email is invalid',
        validCCEmail: (value) => this.validCC(value) || 'This email is invalid',
      },
    }
  },
  methods: {
    emitAgent() {
      Object.keys(this.agent.attributes).forEach((k) => {
        if (this.agent.attributes[k])
          this.agent.attributes[k] = this.agent.attributes[k].trim()
      })

      this.$emit('change', this.agent)
    },
    filePicked(file) {
      let agentCopy = JSON.parse(JSON.stringify(this.agent))
      agentCopy.attributes.image = file.data
      this.agent = agentCopy
    },
    fillInForm(result) {
      this.agent.attributes = {}

      this.agent.attributes.first_name = result.first_name
      this.agent.attributes.last_name = result.last_name
      this.agent.attributes.email = result.email
      this.agent.attributes.cc_email = result.cc_email
      this.agent.attributes.phone = result.phone
      this.agent.attributes.agency_name = result.agency_name
      this.agent.attributes.address = result.address
      this.agent.attributes.city = result.city
      this.agent.attributes.state = result.state
      this.agent.attributes.zip = result.zip
      this.agent.attributes.image_url = result.image
      this.agent.attributes.tag_string = (result.tag_list || []).join(',')
      this.agent.attributes.notes = result.notes
      this.agent.attributes.private_notes = result.private_notes
      this.agent.buying_agent_inspections = result.buying_agent_inspections
      this.agent.selling_agent_inspections = result.selling_agent_inspections

      this.agent = JSON.parse(JSON.stringify(this.agent))

      setTimeout(() => {
        // Allows the interface to render since the ref may not exist otherwise
        if (this.$refs.tagger) {
          this.$refs.tagger.parseValue()
        }
      }, 500)
    },
    resultClicked(result) {
      if (result) {
        this.fillInForm(result)
      } else {
        this.fillInForm({})
      }
      this.toggleMode()
    },
    toggleMode() {
      if (this.searchMode) {
        this.mode = 'form'
        this.$emit('agentSelected', null)
      } else {
        this.mode = 'search'
      }

      this.$emit('mode', this.mode)
    },
    validCC(value) {
      if (value) {
        return !value
          .replace(' ', '')
          .split(',')
          .find((e) => {
            return !e.match(/^[^@\s]+@[^@\s]+$/)
          })
      } else {
        return true
      }
    },
    formatPhoneNumber(value) {
      this.agent.attributes.phone = formatPhone({
        value,
        locale: this.company.attributes.country || 'US',
      })
    },
  },
  mounted() {
    if (this.initialMode) this.mode = this.initialMode
    if (this.attributes)
      this.fillInForm(JSON.parse(JSON.stringify(this.attributes)))
  },
  props: {
    attributes: {},
    captureAddress: {},
    initialMode: {},
    inline: {},
    hideCCEmail: {},
    agentIndex: {
      default: 1,
    },
    id: {},
  },
  watch: {
    agencyAutocompleteSearch(val) {
      if (this.agencyAutocompleteLoading) return
      this.agencyAutocompleteLoading = true
      axios
        .get(
          '/api/v1/autocomplete_choices?' +
            axios.objectToQuery({
              target: 'agency_name',
              q: val,
              company_id: this.company.id,
            })
        )
        .then((response) => {
          if (response && response.data) {
            this.agencyAutocompleteItems = response.data.data.map(
              (x) => x.attributes.value
            )
          }
        })
        .finally(() => {
          this.agencyAutocompleteLoading = false
        })
    },
    agent: {
      handler: function (newVal) {
        this.emitAgent()
      },
      deep: true,
    },
  },
}
</script>

<style scoped>
.search-results .no-results {
  color: #555;
}
.search-results .agent-result {
  display: flex;
  align-items: center;
}
.search-results .agent-result img {
  width: 2em;
  height: 2em;
  margin-right: 1em;
  display: inline-block;
}
.search-results .agent-result .name-combo {
  display: inline-block;
}

.field-image-container {
  position: relative;
  padding-top: 16px;
  cursor: pointer;
}

.field-image-container .v-text-field {
  margin-top: 0;
}

.file-picker-container {
  position: relative;
}

.agent-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 3em;
  height: 3em;
}
</style>

<style>
.agent-search-container
  .file-picker
  .field-image-container
  .v-text-field
  input {
  text-indent: 3.5em;
}

.agent-search-container .notes-field label {
  margin: 6px;
}
</style>
