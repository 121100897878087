<script>
export default {
  beforeDestroy() {
    if (typeof window.ga === 'function') {
      this.slotElement.removeEventListener('click', this.trackClick)
    }
  },
  computed: {
    slotElement() {
      return this.$slots.default[0].elm
    },
  },
  methods: {
    trackClick() {
      window.ga('send', 'event', this.category, this.action, this.label)
    },
  },
  mounted() {
    if (typeof window.ga === 'function') {
      this.slotElement.addEventListener('click', this.trackClick, true)
    }
  },
  render() {
    return this.$slots.default[0]
  },
  props: {
    action: {
      default: 'click',
    },
    category: {
      default: 'button',
    },
    label: {
      required: true,
    },
  },
}
</script>
