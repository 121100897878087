<template>
  <div class="custom-field" :class="{ required: isRequired }">
    <v-text-field
      v-if="textResponse"
      v-model="localValue"
      :title="field.attributes.name"
      @keyup="change"
    >
      <template #label>
        <span v-if="isRequired" class="red--text">*</span>
        <span>{{ field.attributes.name }}</span>
      </template>
    </v-text-field>
    <v-textarea v-if="paragraphResponse" v-model="localValue" @keyup="change">
      <template #label>
        <span v-if="isRequired" class="red--text">*</span>
        <span>{{ field.attributes.name }}</span>
      </template>
    </v-textarea>
    <v-text-field
      v-else-if="numberResponse"
      type="number"
      v-model="localValue"
      @input="change"
    >
      <template #label>
        <span v-if="isRequired" class="red--text">*</span>
        <span>{{ field.attributes.name }}</span>
      </template>
    </v-text-field>
    <v-checkbox
      v-else-if="checkboxResponse"
      v-model="localValue"
      @change="change"
    >
      <template #label>
        <span v-if="isRequired" class="red--text">*</span>
        <span>{{ field.attributes.name }}</span>
      </template>
    </v-checkbox>
    <v-select
      clearable
      v-else-if="dropdownResponse"
      v-model="localValue"
      :items="fieldItems(field)"
      @change="change"
    >
      <template #label>
        <span v-if="isRequired" class="red--text">*</span>
        <span>{{ field.attributes.name }}</span>
      </template>
    </v-select>
    <v-select
      v-else-if="indicatorResponse"
      v-model="localValue"
      :items="indicatorItems"
      @change="change"
    >
      <template #label>
        <span v-if="isRequired" class="red--text">*</span>
        <span>{{ field.attributes.name }}</span>
      </template>
    </v-select>
    <div v-if="dateResponse" class="calendar-overlay">
      <label>
        <span v-if="isRequired" class="red--text">*</span>
        {{ field.attributes.name }}
      </label>
      <flat-pickr
        :name="field.id"
        ref="customFieldCal"
        v-model="localValue"
        :config="dateConfig"
        class="form-control"
        placeholder="Select a Date"
        @on-close="verifyDate('date')"
      />
    </div>
    <div v-if="dateTimeResponse" class="calendar-overlay">
      <label>
        <span v-if="isRequired" class="red--text">*</span>
        {{ field.attributes.name }}
      </label>
      <flat-pickr
        ref="customFieldCal"
        v-model="localValue"
        :config="dateTimeConfig"
        class="form-control"
        placeholder="Select a Date and Time"
        @on-close="verifyDate('datetime')"
      />
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    flatPickr,
  },
  computed: {
    checkboxResponse() {
      return this.fieldType === 'checkbox'
    },
    dateConfig() {
      return {
        enableTime: false,
        dateFormat: 'Y-m-d',
        altInput: true,
        altFormat: $('#date_format').val().replace(/%/g, ''),
      }
    },
    dateResponse() {
      return this.fieldType === 'date'
    },
    dateTimeConfig() {
      return {
        enableTime: true,
        dateFormat: 'Y-m-d h:i K',
        altInput: true,
        altFormat: $('#date_format').val().replace(/%/g, '') + ' h:i K',
      }
    },
    dateTimeResponse() {
      return this.fieldType === 'datetime'
    },
    dropdownResponse() {
      return this.fieldType === 'dropdown'
    },
    fieldType() {
      return this.field.attributes.response_format
    },
    indicatorItems() {
      return [
        { text: 'N/A', value: 'n/a' },
        { text: 'Yes', value: '1' },
        { text: 'No', value: '0' },
      ]
    },
    indicatorResponse() {
      return this.fieldType === 'indicator'
    },
    isRequired() {
      return this.field.attributes.required && !this.checkboxResponse
    },
    numberResponse() {
      return this.fieldType === 'number'
    },
    paragraphResponse() {
      return this.fieldType === 'paragraph'
    },
    textResponse() {
      return this.fieldType === 'text'
    },
  },
  data() {
    return {
      localValue: null,
    }
  },
  methods: {
    change() {
      this.$emit('input', this.localValue)
    },
    fieldItems() {
      return (this.field.attributes.choices || '').split(',')
    },
    loadValue() {
      if (
        this.checkboxResponse &&
        (this.field.attributes.value === 'f' ||
          this.field.attributes.value === '0')
      ) {
        this.localValue = false
        return
      }

      this.localValue =
        this.field.attributes.formatted_value || this.field.attributes.value
    },
    verifyDate(type) {
      let date = this.$refs.customFieldCal.fp.selectedDates[0]
      if (date) {
        let config = type === 'date' ? this.dateConfig : this.dateTimeConfig
        this.localValue = this.$refs.customFieldCal.fp.formatDate(
          date,
          config.dateFormat
        )
      }

      this.change()
    },
  },
  mounted() {
    this.loadValue()
  },
  props: ['field', 'value'],
}
</script>

<style>
.custom-field {
  width: 100%;
}

/* For some weird reason, this is not working */
/* .custom-field.required label::before,
.custom-field.required label:before {
  content: '* ';
  color: #d53636;
  font-weight: 300;
} */

.custom-field .calendar-overlay .form-control {
  color: inherit;
}

.custom-field .v-textarea .v-label:not(.v-label--active) {
  margin-left: 0.5em;
  top: 1em;
}

.custom-field textarea {
  max-height: 4em;
  text-indent: 0.25em;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.custom-field .calendar-overlay label {
  color: rgba(0, 0, 0, 0.54);
}

.custom-field .calendar-overlay input.input::placeholder {
  color: rgba(0, 0, 0, 0.54);
}

.custom-field .calendar-overlay input.input {
  max-height: 32px;
  border-bottom: 1px solid #9e9e9e;
}

.custom-field .calendar-overlay:before {
  bottom: 0;
}
</style>
