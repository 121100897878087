export const addNotification = (state, notification) => {
  notification.id = notificationId(notification)
  notification.priority = getPriority(state)
  notification.klass = `notification-${notification.type}`
  state.notifications.push(notification)
}

export const removeAllNotifications = (state) => {
  state.notifications = []
}

export const removeNotification = (state, notificationId) => {
  state.notifications = state.notifications.map((n) => {
    if (n.id === notificationId) n.dismissed = true
    return n
  })
}

export const removeNotificationCategory = (state, category) => {
  state.notifications = state.notifications.map((n) => {
    if (n.category === category) n.dismissed = true
    return n
  })
}

export const deleteNotificationCategory = (state, category) => {
  state.notifications = state.notifications.filter(
    (n) => n.category !== category
  )
}

export const setShowError = (state, value = true) => {
  state.showError = value
  if (!value) {
    // Clear errors
    state.errors = []
  } else {
    state.errors = state.errors.concat([value])
  }
}

export const setShowSaved = (state, value = true) => {
  typeof value === 'boolean'
    ? (state.savedMessage = null)
    : (state.savedMessage = value)
  state.showSaved = !!value
}

export const setShowSuccess = (state, value = true) => {
  typeof value === 'boolean'
    ? (state.successMessage = null)
    : (state.successMessage = value)
  state.showSuccess = !!value
}

export const setShowMessage = (state, message) => {
  state.showSaved = true
  state.savedMessage = message
}

export const setShowWarning = (state, value = true) => {
  typeof value === 'boolean'
    ? (state.warningMessage = null)
    : (state.warningMessage = value)
  state.showWarning = !!value
}

export const shiftError = (state) => {
  let errors = state.errors.slice()
  errors.shift()
  state.errors = errors
  if (errors.length < 1) {
    state.showError = false
  }
}

export const showError = (state, error) => {
  state.errors = [error].concat(state.errors)
  state.showError = true
}

const getPriority = (state) => {
  return state.notifications.length + 1
}

const notificationId = (notification) => {
  return `${notification.type}-${Math.random().toString().slice(2)}`
}
