<template>
  <v-app id="schedule" :class="{ iframe: isFrame }">
    <v-container class="page">
      <v-stepper v-if="ready" v-model="scheduleStep" class="schedule-stepper">
        <v-stepper-header v-show="!saved">
          <div :key="idx" v-for="(step, idx) in steps">
            <v-tooltip top>
              <v-stepper-step
                slot="activator"
                :editable="idx < scheduleStep"
                :complete="idx < scheduleStep"
                :step="idx + 1"
              >
                {{ step.name }}
              </v-stepper-step>
              <span>{{ step.name }}</span>
            </v-tooltip>
          </div>
        </v-stepper-header>

        <v-stepper-items>
          <template v-for="(step, idx) in steps">
            <v-stepper-content :key="idx" :step="idx + 1">
              <component
                :is="step.component"
                :inspection="inspection"
                :active="scheduleStep === idx + 1"
                :params="stepParams(step.component)"
                @change="stepChange"
              />
              <v-layout
                v-if="step.component === 'ContactInfo' && selectScheduler"
                wrap
                justify-center
                class="scheduler-select"
              >
                <v-flex xs11 md10 class="scheduler-select-inner">
                  <v-layout wrap justify-center>
                    <v-flex xs12 center section class="scheduler-select-header">
                      Who are you?
                    </v-flex>
                    <v-layout wrap justify-center class="scheduler-select-body">
                      <v-flex
                        xs12
                        md4
                        class="scheduler-block"
                        @click="setScheduler('client')"
                      >
                        <div class="scheduler-icon">
                          <i class="fa fa-home" />
                        </div>
                        <div class="scheduler-name light">The Client</div>
                      </v-flex>
                      <v-flex
                        xs12
                        md4
                        class="scheduler-block"
                        @click="setScheduler('agent')"
                      >
                        <div class="scheduler-icon">
                          <i class="fa fa-user" />
                        </div>
                        <div class="scheduler-name light">The Agent</div>
                      </v-flex>
                      <v-flex
                        xs12
                        md4
                        class="scheduler-block"
                        @click="setScheduler(null)"
                      >
                        <div class="scheduler-icon">
                          <i class="fa fa-search" />
                        </div>
                        <div class="scheduler-name light">Someone Else</div>
                      </v-flex>
                    </v-layout>
                  </v-layout>
                </v-flex>
              </v-layout>
              <div v-if="disabled" class="ineligible-wrap">
                <div class="ineligible-message">
                  This company cannot currently accept inspection requests
                  online.<br />Please call to schedule.
                </div>
              </div>
            </v-stepper-content>
          </template>
        </v-stepper-items>
      </v-stepper>
      <div v-if="!saved" class="button-row">
        <div
          v-if="!disabled"
          @click="next"
          class="btn btn-xlarge"
          :class="stepClass"
        >
          <div v-if="!lastStep" class="inner" id="schedule-next-btn">
            Next
            <i class="fa fa-chevron-right" />
          </div>
          <div
            v-else-if="lastStep && !saving"
            class="inner"
            id="schedule-schedule-btn"
          >
            <i class="fa fa-check" />
            Schedule
          </div>
          <div v-else class="inner" id="schedule-saving-btn">
            <i class="fa fa-spinner fa-spin" />
            Saving
          </div>
        </div>
      </div>

      <toast />
    </v-container>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import axios from '../../AxiosService'
import dig from '../../Dig'
import moment from 'moment'
import Toast from '../notifier/Toast'

// Steps
import ContactInfo from './scheduler/ContactInfo'
import ReviewInspection from './scheduler/ReviewInspection'
import SelectDate from './scheduler/SelectDate'
import SelectService from './scheduler/SelectService'

const UTM_FIELDS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
]

export default {
  components: {
    ContactInfo,
    ReviewInspection,
    SelectDate,
    SelectService,
    Toast,
  },
  computed: {
    ...mapState('inspection', [
      'charges',
      'company',
      'customFields',
      'userProfile',
    ]),
    contactFirst() {
      return this.company.attributes.settings.scheduler_contacts_first
    },
    currentComponent() {
      return this.currentStep.component
    },
    currentStep() {
      return this.steps[this.scheduleStep - 1]
    },
    days() {
      let m = moment()
      let days = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]
      return days.map((d) => {
        if (d > 0) m.add(1, 'days')
        return m.clone()
      })
    },
    defaultSteps() {
      let steps = [{ name: 'What & Where', component: 'SelectService' }]

      let middle = [
        { name: 'Who to contact', component: 'ContactInfo' },
        { name: 'When', component: 'SelectDate' },
      ]

      if (!this.contactFirst) middle = middle.reverse()
      steps = steps.concat(middle)

      steps.push({ name: 'Review & Schedule', component: 'ReviewInspection' })
      return steps
    },
    gaEnabled() {
      return (
        dig(this, 'company.attributes.settings.scheduler_completion_page', '')
          .length > 0 &&
        dig(this, 'company.attributes.settings.ga_property', '').length > 0
      )
    },
    isFrame() {
      return !!this.$route.query.iframe
    },
    lastStep() {
      return this.scheduleStep === this.steps.length
    },
    preselectAgent() {
      return (
        this.userProfile.id &&
        this.userProfile.id === this.$route.query.buying_agent_id
      )
    },
    schedulingDisabled() {
      return dig(this, 'company.attributes.scheduling_disabled', false)
    },
    stepClass() {
      return this.lastStep ? 'btn-info' : ''
    },
    title() {
      return `${this.$route.params.companySlug} | Book an inspection`
    },
  },
  created() {
    this.queryParams = Object.assign({}, this.$route.query)
    this.fetchCompany()
  },
  data() {
    return {
      disabled: false,
      inspection: { attributes: { settings: {} } },
      openings: [],
      partialRegistered: false,
      queryParams: {},
      ready: false,
      saved: false,
      saving: false,
      selectScheduler: false,
      schedulerSelected: false,
      scheduleStep: 1,
      steps: [],
    }
  },
  metaInfo() {
    return {
      title: this.title,
    }
  },
  methods: {
    askForScheduler() {
      return (
        !this.schedulerSelected &&
        dig(this, 'inspection.buyer.attributes.email', '').length > 0 &&
        dig(this, 'inspection.agent.attributes.email', '').length > 0
      )
    },
    calculateCharges() {
      return this.$store.dispatch(
        'inspection/calculateCharges',
        this.inspection
      )
    },
    displayErrors(errors) {
      if (errors.length > 0) {
        this.submitErrorsToGA()

        let comp = this.currentStep.component
        let errorMsg = ''

        if (comp === 'SelectService') {
          if (errors.includes('service') || errors.includes('property')) {
            if (errors.includes('service'))
              errorMsg += 'You need to select a Service first!<br/>'
            if (errors.includes('property'))
              errorMsg += 'You forgot to add a property to inspect!<br/>'
          } else {
            let propertyMsg =
              'Your property is missing the following info:<br/>'
            if (errors.includes('address')) propertyMsg += '- an address<br/>'
            if (errors.includes('zip')) propertyMsg += '- a zip code<br/>'
            // Square footage errors
            const squareFootageErrors = '- Its square footage'
            if (errors.includes('square_feet'))
              propertyMsg += `${squareFootageErrors}<br/>`
            if (errors.includes('square_feet_numericality'))
              propertyMsg += `${squareFootageErrors} must be an integer<br/>`
            if (errors.includes('square_feet_negative'))
              propertyMsg += `${squareFootageErrors} must be greater than 0<br/>`
            // Year built errors
            const yearBuiltErrors = '- The year it was built'
            if (errors.includes('year_built'))
              propertyMsg += `${yearBuiltErrors}<br/>`
            if (errors.includes('year_built_numericality'))
              propertyMsg += `${yearBuiltErrors} must be an integer<br/>`
            if (errors.includes('year_built_negative'))
              propertyMsg += `${yearBuiltErrors} must be greater than 0<br/>`
            if (errors.includes('year_built_in_the_future'))
              propertyMsg += `${yearBuiltErrors} can't be in the future<br/>`
            errorMsg += propertyMsg
          }
          if (errors.includes('custom'))
            errorMsg += "You're missing some required additional info!"
        } else if (comp === 'ContactInfo') {
          if (errors.includes('client_email'))
            errorMsg +=
              'You forgot the Client Email! Add it so we can contact you!<br/>'
          if (errors.includes('client_first_name'))
            errorMsg += 'You forgot to add the Client First Name.<br/>'
          if (errors.includes('client_last_name'))
            errorMsg += 'You forgot to add the Client Last Name.<br/>'
          if (errors.includes('client_phone'))
            errorMsg += "It doesn't look like your Client Phone is valid.<br/>"
          if (errors.includes('client_email_invalid'))
            errorMsg += "It doesn't look like your Client email is valid.<br/>"
          if (errors.includes('client_2_email_invalid'))
            errorMsg +=
              "It doesn't look like your Client #2 email is valid.<br/>"
          if (errors.includes('agent_email_invalid'))
            errorMsg += "It doesn't look like your Agent email is valid.<br/>"
          if (errors.includes('selling_agent_email_invalid'))
            errorMsg +=
              "It doesn't look like your Listing Agent email is valid."
        } else if (comp === 'SelectDate') {
          if (errors.includes('datetime'))
            errorMsg +=
              "Select a time you'd like to have your inspection at!<br/>"
          if (errors.includes('inspector'))
            errorMsg += 'Select an inspector for your inspection!'
        }

        this.$store.dispatch('notifier/show', { type: 'Error', msg: errorMsg })
      }
    },
    duration() {
      return this.charges.reduce((summ, c) => {
        summ += c.duration
        return summ
      }, 0)
    },
    fetchCompany() {
      this.$store
        .dispatch('inspection/fetchCompany', {
          id: this.$route.params.companySlug,
        })
        .then(() => {
          this.steps = this.defaultSteps
          this.fetchCustomFields().then(() => {
            this.$store
              .dispatch('inspection/fetchUserProfile')
              .then(() => {
                this.initializeScheduling()
              })
              .catch(() => {
                this.initializeScheduling()
              })
          })
        })
    },
    fetchCustomFields() {
      return this.$store.dispatch('inspection/fetchCustomFields').then(() => {
        this.inspection.custom_fields_attributes = this.customFields.map(
          (cf) => {
            return {
              attributes: {
                name: cf.attributes.name,
                required: cf.attributes.required,
                hidden: cf.attributes.hidden,
                mobile: cf.attributes.mobile,
                choices: cf.attributes.choices,
                custom_field_template_id: cf.id,
                response_format: cf.attributes.response_format,
                priority: cf.attributes.priority,
                value: cf.attributes.default_value,
              },
            }
          }
        )
      })
    },
    fetchOpenings() {
      let requests = this.openingUrls().map((url) => {
        return axios.get(url)
      })

      return Promise.all(requests).then((response) => {
        this.openings = this.days.map((d, i) => {
          return {
            date: d.toDate(),
            times: response[i].data,
          }
        })
      })
    },
    initializeInspection() {
      this.inspection.attributes.company_id = this.company.id
      this.inspection.attributes.requested_on = new Date()
      this.inspection.attributes.paid_required =
        this.company.attributes.settings.paid_required_default
      this.inspection.attributes.settings.scheduled_at = new Date()
      this.inspection.attributes.settings.scheduled_by = 'Online Scheduler'
      this.inspection.attributes.settings.enable_auto_tracks =
        this.company.attributes.settings.enable_auto_tracks
      this.inspection.attributes.settings.occupied = false
      this.inspection.attributes.settings.utilities_on = false
      // Remove once new Client Portal is live
      this.inspection.attributes.settings.beta_client_portal =
        this.company.attributes.settings.client_portal_default

      if (this.$route.query.referral_source) {
        this.inspection.referral_source = this.$route.query.referral_source
      }

      if (parseInt(this.company.attributes.settings.last_order_id)) {
        this.inspection.attributes.order_id =
          parseInt(this.company.attributes.settings.last_order_id) + 1
      }

      if (this.company.attributes.settings.scheduler_require_confirmation) {
        this.inspection.attributes.confirmed = false
        this.inspection.attributes.settings.disable_notifications = true
      } else {
        this.inspection.attributes.confirmed = true
      }

      let isCCR =
        dig(this, 'userProfile.type', '') === 'call_center_rep' ||
        this.$route.params.ccr_view
      if (isCCR) {
        this.inspection.attributes.settings.scheduled_by = `CCR: ${this.userProfile.attributes.name}`
        this.inspection.attributes.call_center_id = dig(
          this,
          'company.attributes.call_center_id'
        )
      }

      UTM_FIELDS.map((field) => {
        const param = this.queryParams[field]
        if (param) this.inspection.attributes[field] = param
      })
    },
    initializeScheduling() {
      if (this.schedulingDisabled) {
        this.disabled = true
        this.ready = true
      } else {
        this.initializeInspection()
        this.ready = true
      }
    },
    next() {
      if (!this.lastStep) {
        let valid = this.validateStep()
        let proceed = false

        if (valid) {
          if (this.currentComponent === 'SelectService') {
            this.calculateCharges().then(() => {
              this.fetchOpenings()
            })
            proceed = true
          } else if (this.currentComponent === 'ContactInfo') {
            if (this.askForScheduler()) {
              this.selectScheduler = true
            } else {
              proceed = true
              this.registerPartial()
            }
          } else {
            this.calculateCharges().then(() => {})
            proceed = true
          }
          if (proceed) {
            this.scheduleStep += 1
            let offset = document.querySelector('#page-content').offsetHeight
            window.scrollTo({ left: 0, top: offset, behavior: 'smooth' })
          }
        }
      } else {
        this.save()
      }
    },
    openingUrls() {
      let lat = dig(this.inspection, 'property.attributes.lat', '')
      let lng = dig(this.inspection, 'property.attributes.lng', '')
      let duration = this.duration()
      let serviceIds = dig(this.inspection, 'services', []).map((s) => {
        return s.id
      })
      let addOnIds = dig(this.inspection, 'add_ons', []).map((ao) => {
        return ao.id
      })

      serviceIds =
        serviceIds.length > 0 ? serviceIds.join('&service_id[]=') : null
      addOnIds =
        addOnIds.length > 0 ? addOnIds.join('&service_add_on_id[]=') : null

      return this.days.map((d) => {
        let date = d.format('YYYY-M-D')
        let url = `/time_slots/open/${this.company.id}/${date}?duration=${duration}&lng=${lng}&lat=${lat}`
        if (serviceIds) url += `&service_id[]=${serviceIds}`
        if (addOnIds) url += `&service_add_on_id[]=${addOnIds}`
        return url
      })
    },
    registerPartial() {
      if (
        (this.company.attributes.settings.scheduler_receive_partial_email ||
          this.company.attributes.settings.scheduler_receive_partial_text) &&
        !this.partialRegistered
      ) {
        let data = `Schedule by ${this.inspection.attributes.settings.scheduled_by} In Progress\n\nServices: `
        data += this.inspection.services
          .map((s) => {
            return s.attributes.name
          })
          .join(' ')
        data += '\n'

        let buyer = this.inspection.buyer.attributes
        if (dig(buyer, 'first_name', '').length > 0)
          data += `Client First Name: ${buyer.first_name}\n`
        if (dig(buyer, 'last_name', '').length > 0)
          data += `Client Last Name: ${buyer.last_name}\n`
        if (dig(buyer, 'phone', '').length > 0)
          data += `Client Phone: ${buyer.phone}\n`
        if (buyer.email.length > 0) data += `Client Email: ${buyer.email}\n`
        data += '\n'

        let agent = dig(this, 'inspection.agent.attributes')
        if (agent) {
          if (dig(agent, 'first_name', '').length > 0)
            data += `Agent First Name: ${agent.first_name}\n`
          if (dig(agent, 'last_name', '').length > 0)
            data += `Agent Last Name: ${agent.last_name}\n`
          if (dig(agent, 'phone', '').length > 0)
            data += `Agent Phone: ${agent.phone}\n`
          if (dig(agent, 'email', '').length > 0)
            data += `Agent Email: ${agent.email}\n`
          data += '\n'
        }

        let property = this.inspection.property.attributes
        data += `${property.address} ${property.city} ${property.state} ${property.zip}\n`
        data += `${property.square_feet} sq ft\n`
        data += `Built ${property.year_built}`

        let address = `${property.address}, ${property.city} ${property.state} ${property.zip}`

        axios
          .post('/schedule_in_progress', {
            company_id: this.company.id,
            info: data,
            address: address,
          })
          .then(() => {
            this.partialRegistered = true
          })
      }
    },
    save() {
      if (!this.saving) {
        this.saving = true

        let inspectionObject = this.saveInspectionObject()

        this.checkForOverlaps().then((conflicts) => {
          if (conflicts) {
            this.fetchOpenings().then(() => {
              alert(
                "We're sorry, but this time is no longer available. Please select a different time for your inspection."
              )
              this.scheduleStep =
                this.steps.findIndex((s) => s.name === 'When') + 1
              this.saving = false
            })
          } else {
            this.$store
              .dispatch('inspection/createInspection', inspectionObject)
              .then((response) => {
                this.saved = true
                this.submitToGA()
              })
              .catch((e) => {
                console.log(e)
                this.saving = false
              })
          }
        })
      }
    },
    saveInspectionObject() {
      let object = {
        inspection: JSON.parse(JSON.stringify(this.inspection)).attributes,
      }
      object.inspection.property_attributes = dig(
        this,
        'inspection.property.attributes'
      )

      Object.keys(object.inspection.settings).forEach((k) => {
        object.inspection[k] = object.inspection.settings[k]
      })

      object.inspection.duration = this.duration()
      object.inspection.buyer_attributes = dig(
        this.inspection,
        'buyer.attributes'
      )
      object.inspection.buyer_2_attributes = dig(
        this.inspection,
        'buyer_2.attributes'
      )
      if (this.preselectAgent) {
        object.inspection.buying_agent_id = this.$route.query.buying_agent_id
      } else {
        object.inspection.agent_attributes = dig(
          this.inspection,
          'agent.attributes'
        )
      }
      object.inspection.selling_agent_attributes = dig(
        this.inspection,
        'selling_agent.attributes'
      )
      object.inspection.inspector_id = this.inspection.inspector.id

      object.inspection.services = (this.inspection.services || []).map((s) => {
        return s.id
      })
      object.inspection.add_ons = (this.inspection.add_ons || []).map((ao) => {
        return ao.id
      })
      object.inspection.internachi_buyback = !!(
        this.inspection.services || []
      ).find((s) => s.attributes.internachi_buyback)

      object.inspection.custom_fields_attributes =
        this.inspection.custom_fields_attributes.map((cf) => {
          return cf.attributes
        })

      if (dig(this, 'inspection.inspectorRequested')) {
        object.inspector_requests = [this.inspection.inspector.id]
      }

      return object
    },
    checkForOverlaps() {
      let dt = moment(
        this.inspection.attributes.datetime,
        'ddd MMM DD YYYY h:m A'
      )
      const datetimeFormatted = dt.format('YYYY-MM-DD hh:mma')
      let params = {
        duration: this.duration(),
        inspection_placeholder_id:
          this.inspection.attributes.inspection_placeholder_id,
        inspector_ids: [this.inspection.inspector.id],
        online_scheduler: true,
      }

      return axios
        .get(`/can_schedule/${this.company.id}/${datetimeFormatted}`, {
          params: params,
        })
        .then((response) => {
          let confs = response.data.conflicts || ''
          confs = confs.split('.').filter((c) => c.match(/inspection|event/))
          if (confs.length > 0) {
            return confs
          }
        })
    },
    setScheduler(scheduler) {
      let name = null

      if (scheduler === 'client') {
        name = 'Client'
      } else if (scheduler === 'agent') {
        let first = dig(this, 'inspection.agent.attributes.first_name', '')
        let last = dig(this, 'inspection.agent.attributes.last_name', '')

        if (first.length > 0 && last.length > 0) {
          name = `${first} ${last}`
        } else {
          name = dig(this, 'inspection.agent.attributes.email', '')
        }

        name = `Agent ${name}`
      }

      if (name)
        this.inspection.attributes.settings.scheduled_by = `${name} via the Online Scheduler`

      this.selectScheduler = false
      this.schedulerSelected = true
      this.next()
    },
    stepChange() {
      if (this.currentComponent === 'SelectDate') {
        this.next()
      }
    },
    stepParams(component) {
      if (component === 'SelectDate') {
        return {
          openings: this.openings,
        }
      } else if (component === 'ReviewInspection') {
        return {
          saved: this.saved,
        }
      } else {
        return {}
      }
    },
    submitErrorsToGA() {
      if (typeof ga === 'function') {
        // eslint-disable-next-line no-undef
        ga(
          'send',
          'event',
          'trigger',
          'alert',
          `step_${this.scheduleStep}_missing_info`
        )
      }
    },
    submitToGA() {
      if (typeof ga === 'function' && this.gaEnabled) {
        // eslint-disable-next-line no-undef
        ga('create', this.company.attributes.settings.ga_property, {
          cookieFlags: 'max-age=7200;secure;samesite=none',
        })
        // eslint-disable-next-line no-undef
        ga(
          'send',
          'pageview',
          this.company.attributes.settings.scheduler_completion_page
        ) // eslint-disable-line
      }
    },
    validateStep() {
      let errors = []
      let comp = this.currentStep.component
      if (comp === 'SelectService') {
        if (dig(this, 'inspection.services', []).length === 0)
          errors.push('service')
        let property = this.inspection.property
        if (!property) errors.push('property')
        if (property) {
          if (dig(property, 'attributes.address', '').length === 0)
            errors.push('address')
          if (dig(property, 'attributes.zip', '').length === 0)
            errors.push('zip')
          if (this.company.attributes.company_type.can_use_square_footage) {
            // Square feet validation
            const squareFeet = Number(
              dig(property, 'attributes.square_feet', '')
            )
            if (squareFeet === 0) {
              errors.push('square_feet')
            } else if (!Number.isInteger(squareFeet)) {
              errors.push('square_feet_numericality')
            } else if (squareFeet <= 0) {
              errors.push('square_feet_negative')
            }
          }
          if (this.company.attributes.company_type.can_use_year_built) {
            // Years built validation
            const yearsBuilt = Number(
              dig(property, 'attributes.year_built', '')
            )
            const currentYear = new Date().getFullYear()
            if (yearsBuilt === 0) {
              errors.push('year_built')
            } else if (!Number.isInteger(yearsBuilt)) {
              errors.push('year_built_numericality')
            } else if (yearsBuilt <= 0) {
              errors.push('year_built_negative')
            } else if (yearsBuilt > currentYear) {
              errors.push('year_built_in_the_future')
            }
          }
        }
        let customError = false
        this.inspection.custom_fields_attributes.forEach((cf) => {
          if (
            cf.attributes.required &&
            cf.attributes.response_format !== 'checkbox' &&
            dig(cf, 'attributes.value', '').length === 0
          )
            customError = true
        })

        if (customError) errors.push('custom')
      } else if (comp === 'ContactInfo') {
        let buyerEmail = dig(this, 'inspection.buyer.attributes.email', '')
        let buyerShowAsCompany = dig(
          this,
          'inspection.buyer.attributes.show_as_company',
          false
        )
        let buyerFirstName = dig(
          this,
          'inspection.buyer.attributes.first_name',
          ''
        )
        let buyerLastName = dig(
          this,
          'inspection.buyer.attributes.last_name',
          ''
        )
        let buyerPhone = dig(this, 'inspection.buyer.attributes.phone', '')

        if (buyerEmail.length === 0) {
          errors.push('client_email')
        } else if (!buyerEmail.match(/^[^@\s]+@[^@\s]+$/)) {
          errors.push('client_email_invalid')
        }

        if (buyerFirstName.length === 0) {
          errors.push('client_first_name')
        }

        if (!buyerShowAsCompany && buyerLastName.length === 0) {
          errors.push('client_last_name')
        }

        // There shuld be at least 8 digits
        if (buyerPhone.replace(/[^0-9]/g, '').length <= 8) {
          errors.push('client_phone')
        }

        let buyer2Email = dig(this, 'inspection.buyer_2.attributes.email', '')
        if (buyer2Email.length > 0 && !buyer2Email.match(/^[^@\s]+@[^@\s]+$/))
          errors.push('client_2_email_invalid')

        let agentEmail = dig(this, 'inspection.agent.attributes.email', '')
        if (agentEmail.length > 0 && !agentEmail.match(/^[^@\s]+@[^@\s]+$/))
          errors.push('agent_email_invalid')

        let sellEmail = dig(
          this,
          'inspection.selling_agent.attributes.email',
          ''
        )
        if (sellEmail.length > 0 && !sellEmail.match(/^[^@\s]+@[^@\s]+$/))
          errors.push('selling_agent_email_invalid')
      } else if (comp === 'SelectDate') {
        if (dig(this, 'inspection.attributes.datetime', '').length === 0)
          errors.push('datetime')
        if (
          this.company.attributes.settings.scheduler_allow_inspector_choice &&
          !dig(this, 'inspection.inspector')
        )
          errors.push('inspector')
      }

      this.displayErrors(errors)
      return errors.length === 0
    },
  },
}
</script>

<style scoped>
.page {
  padding-top: 2em;
}
.button-row {
  width: 100%;
  margin-top: 2em;
  text-align: right;
}

.schedule-stepper {
  overflow: hidden !important;
}

.scheduler-select {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.7);
  align-items: flex-start;
  align-content: center;
}

.scheduler-select-header {
  color: #fff;
  font-size: 1.25em;
  padding: 1rem;
  background-color: #5c9ccf;
  border-bottom: 5px solid #45769c;
  font-size: 2em;
  font-weight: 300;
}

.scheduler-select-inner {
  background-color: #fff;
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22),
    0 25px 55px 0 rgba(0, 0, 0, 0.21);
}

.scheduler-select-body {
  padding: 2em;
}

.scheduler-block {
  padding: 2em 1em;
  background-color: #fff;
  text-align: center;
  transition: background 0.1s linear;
  cursor: pointer;
}

.scheduler-block:hover {
  background-color: #f9f9f9;
}

.scheduler-block .fa {
  font-size: 4em;
  color: #5c9ccf;
}

.scheduler-block .scheduler-name {
  font-size: 1.1em;
  margin: 0.5em;
}

.ineligible-wrap {
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 1.5em;
}

@media (max-width: 800px) {
  .button-row {
    text-align: center;
  }
}
</style>

<style lang="scss">
body.scheduler {
  .flatpickr-weekdaycontainer {
    display: flex;
    flex: 1;
  }
}

#schedule {
  .v-stepper {
    overflow: visible;
    .v-stepper__items {
      overflow: visible;
      .v-stepper__wrapper {
        overflow: visible;
      }
    }
  }
  border-top: 1px solid #d9d9d9;

  .application--wrap {
    min-height: 55vh;
  }

  h3 {
    color: #45769c;
    font-weight: 300;
    border: none;
  }

  .fill-width {
    width: 100%;
  }

  .v-stepper__label {
    text-shadow: none;
  }

  .v-input__slot:before {
    display: none;
  }
  .v-input__control .v-input__slot:after {
    display: none;
  }
  .v-menu {
    border-bottom: 1px solid #9e9e9e;
  }
  .v-select__selections input {
    border-bottom: none;
  }
  .v-select .v-input__slot .v-select__slot {
    border-bottom: 1px solid #9e9e9e;
  }

  .v-snack__content {
    height: auto;
  }

  &.iframe {
    min-height: 100vh;
    background-color: #fff;

    .container {
      width: 100%;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      align-content: baseline;
      justify-content: center;
      background-color: #fff;
    }

    .v-stepper {
      width: inherit;
    }

    .button-row {
      text-align: center;
      .btn {
        margin: 0;
      }
    }
  }
}

// Disable android auto-zoom
input {
  touch-action: none;
}
</style>
