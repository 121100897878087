<template>
  <v-layout wrap id="scheduler-select-service">
    <v-layout wrap fill-width section>
      <v-flex xs12>
        <v-flex xs12 vpx-1>
          <h3>
            <i class="fa fa-file-o" />
            SERVICES
          </h3>
        </v-flex>
        <v-flex xs12 class="small" v-if="bnplEnabled">
          <div class="left-margin">
            Buy Now Pay Later Options Available for This Order
          </div>
          <v-layout wrap class="bnpl-providers">
            <div
              class="left-margin"
              v-if="this.company.attributes.settings.enable_bnpl_affirm"
            >
              <img
                alt="affirm"
                role="presentation"
                class="bnpl-img"
                src="https://js.stripe.com/v3/fingerprinted/img/AffirmFullLogo-025e93b73bec0795257fad3a337fde1d.svg"
              />
            </div>
            <div
              class="left-margin"
              v-if="this.company.attributes.settings.enable_bnpl_afterpay"
            >
              <img
                alt="afterpay"
                class="bnpl-img"
                src="https://js.stripe.com/v3/fingerprinted/img/AfterpayFullLogo-a4d4a1afe4290508f322096846fb9173.svg"
              />
            </div>
          </v-layout>
        </v-flex>
        <v-flex xs12>
          <service-selection
            display="columns"
            :menuProps="serviceMenuProps"
            :loadResources="true"
            @serviceChange="updateServiceIds"
            @addonChange="updateAddOnIds"
          />
        </v-flex>
      </v-flex>
    </v-layout>
    <v-layout wrap fill-width section>
      <v-flex xs12>
        <v-flex xs12>
          <h3>
            <i class="fa fa-map-marker" />
            LOCATION
          </h3>
        </v-flex>
        <v-flex xs12>
          <property-form
            @propertyUpdated="updateProperty"
            @propertyReady="updateProperty"
          />
        </v-flex>
      </v-flex>
    </v-layout>
    <v-layout v-if="hasCustomFields" wrap fill-width section>
      <v-flex xs12 md7>
        <v-layout wrap>
          <v-flex xs12>
            <h3>
              <i class="fa fa-bars" />
              Additional Info
            </h3>
          </v-flex>
          <template v-for="(field, index) in inspection.custom_fields_attributes">
            <v-flex
              xs12
              md6
              vpx-1
              class="custom-field-container"
              :key="field.attributes.custom_field_template_id"
              :id="`additional-info-field__${index}`"
            >
              <custom-field :field="field" v-model="field.attributes.value" />
            </v-flex>
          </template>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'
import CustomField from '../CustomField'
import PropertyForm from '../PropertyForm'
import ServiceSelection from '../ServiceSelection'

export default {
  components: {
    CustomField,
    PropertyForm,
    ServiceSelection,
  },
  computed: {
    ...mapState('inspection', [
      'addOns',
      'customFields',
      'services',
      'company',
    ]),
    hasCustomFields() {
      return (this.inspection.custom_fields_attributes || []).length > 0
    },
    serviceMenuProps() {
      return { maxHeight: '200' }
    },
    bnplEnabled() {
      return (
        this.company.attributes.settings.enable_bnpl &&
        this.company.attributes.settings.enable_bnpl_for_all_inspections &&
        (this.company.attributes.settings.enable_bnpl_affirm ||
          this.company.attributes.settings.enable_bnpl_afterpay)
      )
    },
  },
  data() {
    return {
      addonIds: [],
      property: null,
      serviceIds: [],
    }
  },
  methods: {
    updateAddOnIds(ids) {
      this.addonIds = ids
      this.inspection.add_ons = ids.map((id) => {
        let addon = this.addOns.find((ao) => {
          return ao.attributes.id === id
        })
        return addon
      })
    },
    updateProperty(property) {
      this.$set(this, 'property', property)
      this.inspection.property = property
    },
    updateServiceIds(ids) {
      this.serviceIds = ids
      this.inspection.services = ids.map((id) => {
        let service = this.services.find((s) => {
          return s.attributes.id === id
        })
        return service
      })
    },
  },
  props: ['inspection'],
}
</script>

<style scoped>
.custom-field-container {
  display: flex;
  align-items: center;
}
.left-margin {
  margin-left: 10px;
}

.bnpl-img {
  max-height: 25px;
}

.bnpl-providers {
  margin-top: 15px;
}
</style>

<style lang="scss">
#scheduler-select-service {
  .property-square-feet label:before,
  .property-year-built label:before {
    content: '* ';
    color: #d53636;
    font-weight: 300;
  }

  .property-container .toggle.btn-flat {
    display: none;
  }
}
</style>
